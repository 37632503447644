import React from 'react';
import {
  IconButton,
  Typography,
  Tooltip
} from '@mui/material';
import { Clear } from '@mui/icons-material';
import { PopoverListButtons } from '../../../Utils/styledComps';
const CustomHomePopoverContent = ({ home, handlePopoverClose }) => {
  return (
    <div style={{ height: "500px", width: '300px' }}>
      <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center", marginRight: "10px" }}>
        <Typography sx={{ m: 0, pl: 2, color:'#f0f2f7', fontSize: 18, fontWeight: 800 }}>Home</Typography>
        <IconButton edge="end" sx={{ borderRadius: 0 }} onClick={handlePopoverClose}>
          <Clear />
        </IconButton>
      </div>
      <div style={{ display: "flex", columnGap: 8, margin: "10px", flexWrap: "wrap" }}>
        {home.map((house, index) => (
          <Tooltip key={index} title={house.label}>
            <PopoverListButtons onClick={house.onClick}>
              {house.icon}
            </PopoverListButtons>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};

export default CustomHomePopoverContent;
