import React, { useState,useRef } from 'react';
import { TextField, Button, Box, IconButton, Typography, RadioGroup, FormControlLabel, Radio , InputAdornment} from '@mui/material';
import { Add, Remove , Clear, Upload } from '@mui/icons-material';
import Papa from 'papaparse'; 
import * as XLSX from 'xlsx';

const LineChartForm = ({ onAddChart }) => {
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState('');
  const [entries, setEntries] = useState({
    labels: [''],
    datasets: [
      {
        label: '',
        data: [],
        borderColor: '#000000',
        backgroundColor: '#000000',
        fill: true,
      },
    ],
  });

  const handleUploadClick = (e) => {
    e.stopPropagation();

    fileInputRef.current.click();
  };

  const handleClearClick = () => {
    setFileName('');
  };

const handleFileUpload = (e) => {
  const file = e.target.files[0];

  if (file) {
    const reader = new FileReader();
    const fileExtension = file.name.split('.').pop().toLowerCase();

    if (fileExtension === 'csv') {
      reader.onload = (f) => {
        const data = f.target.result;
        Papa.parse(data, {
          header: true,
          complete: (results) => {
            const parsedData = results.data;
            const extractedLabels = parsedData.map((row) => row['Labels']);
            const extractedData = parsedData.map((row) => parseFloat(row['data']) || 0);
            const extractedBorderColor = parsedData.map((row) => row['borderColor'] || '#000000');
            const extractedBackgroundColor = parsedData.map((row) => row['backgroundColor'] || '#000000');
            const datasetLabel = parsedData[0]?.label || 'Dataset';

            setEntries((prevEntries) => ({
              labels: extractedLabels,
              datasets: [
                {
                  ...prevEntries.datasets[0],
                  label: datasetLabel,
                  data: extractedData,
                  borderColor: extractedBorderColor[0],
                  backgroundColor: extractedBackgroundColor[0],
                },
              ],
            }));
          },
        });
      };
      reader.readAsText(file);
    } else if (fileExtension === 'xlsx') {
      reader.onload = (f) => {
        const data = new Uint8Array(f.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        const extractedLabels = parsedData.slice(1).map((row) => row[0]);
        const extractedData = parsedData.slice(1).map((row) => parseFloat(row[1]) || 0);
        const extractedBorderColor = parsedData.slice(1).map((row) => row[2] || '#000000');
        const extractedBackgroundColor = parsedData.slice(1).map((row) => row[3] || '#000000');
        const datasetLabel = parsedData[1]?.[4] || 'Dataset';

        setEntries((prevEntries) => ({
          labels: extractedLabels,
          datasets: [
            {
              ...prevEntries.datasets[0],
              label: datasetLabel,
              data: extractedData,
              borderColor: extractedBorderColor[0],
              backgroundColor: extractedBackgroundColor[0],
            },
          ],
        }));
      };
      reader.readAsArrayBuffer(file);
    }

    setFileName(file.name);
  }
};


  const handleLabelChange = (index, value) => {
    const newLabels = [...entries.labels];
    newLabels[index] = value;
    setEntries({ ...entries, labels: newLabels });
  };

  const handleDatasetChange = (index, field, value) => {
    const newDatasets = [...entries.datasets];
    newDatasets[index] = { ...newDatasets[index], [field]: value };
    setEntries({ ...entries, datasets: newDatasets });
  };

  const handleDataChange = (datasetIndex, dataIndex, value) => {
    const newDatasets = [...entries.datasets];
    const newData = [...newDatasets[datasetIndex].data];
    newData[dataIndex] = parseFloat(value) || 0;
    newDatasets[datasetIndex] = { ...newDatasets[datasetIndex], data: newData };
    setEntries({ ...entries, datasets: newDatasets });
  };

  const handleAddDataset = () => {
    setEntries((prevEntries) => ({
      ...prevEntries,
      datasets: [
        ...prevEntries.datasets,
        {
          label: '',
          data: [],
          borderColor: '#000000',
          backgroundColor: '#000000',
          fill: true,
        },
      ],
    }));
  };

  const handleRemoveDataset = (index) => {
    const newDatasets = entries.datasets.filter((_, i) => i !== index);
    setEntries({ ...entries, datasets: newDatasets });
  };

  const handleAddLabel = () => {
    setEntries((prevEntries) => ({
      ...prevEntries,
      labels: [...prevEntries.labels, ''],
    }));
  };

  const handleRemoveLabel = (index) => {
    const newLabels = entries.labels.filter((_, i) => i !== index);
    setEntries({ ...entries, labels: newLabels });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      if (!entries || entries.length === 0) {
        throw new Error('No entries available for the chart.');
      }
      onAddChart('line', entries);
  
      console.log('Line: handleSubmit: ', entries);
  
    } catch (error) {
      alert(`Submission error: ${error.message}`);
    }
  };

  return (
    <Box sx={{ px: 1.5, py: 1.5 }}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography>Line Chart</Typography>
      </Box>
      <Box component="form" onSubmit={handleSubmit}>
        <Typography fontSize={18} sx={{ mb: 2 }}>Labels</Typography>
        {entries.labels.map((label, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <TextField
              fullWidth
              label={`Label ${index + 1}`}
              value={label}
              onChange={(e) => handleLabelChange(index, e.target.value)}
              sx={{ mr: 2 }}
            />
            <IconButton onClick={() => handleRemoveLabel(index)}>
              <Remove />
            </IconButton>
          </Box>
        ))}
        <Button variant="contained" color="secondary" onClick={handleAddLabel}>
          <Add /> Add Label
        </Button>

        {entries.datasets.map((dataset, datasetIndex) => (
          <Box key={datasetIndex} sx={{ my: 3 }}>
            <Typography fontSize={18} sx={{ mb: 2 }}>Dataset {datasetIndex + 1}</Typography>
            <Box display="flex" alignItems="center">
              <TextField
                fullWidth
                label="Dataset Label"
                value={dataset.label}
                onChange={(e) => handleDatasetChange(datasetIndex, 'label', e.target.value)}
                sx={{ mr: 2 }}
              />
              {entries.datasets.length > 1 && (
                <IconButton onClick={() => handleRemoveDataset(datasetIndex)}>
                  <Remove />
                </IconButton>
              )}
            </Box>
            <TextField
              fullWidth
              label="Border Color"
              type="color"
              value={dataset.borderColor}
              onChange={(e) => handleDatasetChange(datasetIndex, 'borderColor', e.target.value)}
              sx={{ my: 2 }}
            />
            <TextField
              fullWidth
              label="Background Color"
              type="color"
              value={dataset.backgroundColor}
              onChange={(e) => handleDatasetChange(datasetIndex, 'backgroundColor', e.target.value)}
              sx={{ my: 2 }}
            />

            <Typography fontSize={16} sx={{ mb: 2 }}>Data Points</Typography>
            {entries.labels.map((label, dataIndex) => (
              <TextField
                key={dataIndex}
                fullWidth
                label={`Data for ${label || `Label ${dataIndex + 1}`}`}
                type="number"
                value={dataset.data[dataIndex] || ''}
                onChange={(e) => handleDataChange(datasetIndex, dataIndex, e.target.value)}
                sx={{ mb: 2 }}
              />
            ))}
            <input ref={fileInputRef} type="file" accept=".xlsx, .xls, .csv" style={{ display: 'none' }} onChange={handleFileUpload} />
            <TextField

              value={fileName}
              variant="outlined"
              onClick={handleUploadClick}
              placeholder="Upload a file"
              inputProps={{
                style: {
                  cursor: fileName ? 'default' : 'pointer',
                  color: '#ffffff'
                }
              }}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment sx={{ p: 0 }} position="end">
                    <IconButton sx={{ borderRadius: 0, "&:hover": { color: 'blue' } }} onClick={handleUploadClick}>
                      <Upload />
                    </IconButton>
                    {fileName && (
                      <IconButton sx={{ borderRadius: 0, "&:hover": { color: 'red' } }} onClick={handleClearClick}>
                        <Clear />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              />
          </Box>
        ))}
        <Button variant="contained" color="secondary" onClick={handleAddDataset}>
          <Add /> Add Dataset
        </Button>

        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 3 }}>
          <Button type="submit" variant="contained" color="primary">
            Add Line Chart
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default LineChartForm;
