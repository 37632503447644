import React, { useEffect, useState } from 'react';
import {
    IconButton,
    Typography,
    Tooltip,
    TextField,
    Button
} from '@mui/material';
import { ArrowBack, Clear } from '@mui/icons-material';
import { StyledSubMenuContainer } from '../../../Utils/styledComps';
const CustomSprayPopoverContent = ({
    // selectedFeature,
    // spray,
    spraySelectRef,
    handleSpraying,
    handlePopoverClose,
    areaOfSpray, setAreaOfSpray,
    numberOfCopies,
    setNumberOfCopies,
    activeObj,

    // setSelectedFeature
}) => {
    
    const renderPopoverContent = () => {
        return (
            <div>
                <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center", marginRight: "10px" }}>
                    <Typography sx={{ m: 0, pl: 2, fontSize: 18, color: '#f0f2f7', fontWeight: 800 }}>Spray Shapes</Typography>
                    <IconButton edge="end" sx={{ borderRadius: 0 }} onClick={handlePopoverClose}>
                        <Clear />
                    </IconButton>
                </div>
                <StyledSubMenuContainer>
                {activeObj?
                <div style={{ display: "flex", rowGap: 8,flexDirection:'column', margin: "10px", flexWrap: "wrap" }}>
                    <TextField type='number' 
                    value={numberOfCopies} 
                    label='No. Of Copies' 
                    onChange={(e)=>{setNumberOfCopies(e.target.value);console.log('no.OfCopies: ', e.target.value, numberOfCopies)}}
                    />
                    <TextField type='number' 
                    value={areaOfSpray} 
                    label='Area Of Spray' 
                    onChange={(e)=>setAreaOfSpray(e.target.value)}
                    />
                    <Button variant='contained' onClick={handleSpraying}>{spraySelectRef.current ? 'Disable':'Enable'}</Button>
                </div>:
                spraySelectRef.current ? 
                handleSpraying()
                :
                <div style={{ display: "flex", rowGap: 8,flexDirection:'column', margin: "10px", flexWrap: "wrap" }}>
                    Please Select an object frst.
                </div>}
                </StyledSubMenuContainer>
                
            </div>
        );
    };

    return (
        <>
            {renderPopoverContent()}
        </>
    );
};

export default CustomSprayPopoverContent;
