import React, { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import DpiDialog from "./DpiDialog"; 
import {
  handleDownloadPNG,
  handleDownloadJPEG,
  handleDownloadSVG,
  handleDownloadPDF,
  handleDownloadBitmap,
  handleDownloadHighDPI,
  handleDownloadTIFF,
} from "../../../Tools/paintTools";

const DownloadMenu = ({ anchorEl, handleClose, mainCanvas, formats, defaultDpi = 300 }) => {
  const [dpiDialogOpen, setDpiDialogOpen] = useState(false);
  const [dpi, setDpi] = useState(defaultDpi);

  const openDpiDialog = () => {
    setDpiDialogOpen(true);
    handleClose(); 
  };


  const closeDpiDialog = () => {
    setDpiDialogOpen(false);
  };

  const downloadFile = (format) => {
    const canvas = mainCanvas;
    if (!canvas) {
      console.error("Canvas not found");
      return;
    }

    switch (format) {
      case "png":
        handleDownloadPNG(canvas);
        break;
      case "jpeg":
        handleDownloadJPEG(canvas);
        break;
      case "svg":
        handleDownloadSVG(canvas);
        break;
      case "pdf":
        handleDownloadPDF(canvas);
        break;
      case "tiff":
        handleDownloadTIFF(canvas);
        break;
      case "bitmap":
        handleDownloadBitmap(canvas);
        break;
      case "dpi":
        openDpiDialog();  
        return;
      default:
        console.error("Unsupported format");
        break;
    }

    handleClose(); 
  };

  return (
    <>
      {/* Main download menu */}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {formats.map((format) => (
          <MenuItem key={format} onClick={() => downloadFile(format)}>
            {format.toUpperCase()}
          </MenuItem>
        ))}
      </Menu>

      {/* DPI Dialog */}
      <DpiDialog
        open={dpiDialogOpen}
        onClose={closeDpiDialog}
        dpi={dpi}
        setDpi={setDpi}
        onDownload={() => handleDownloadHighDPI(mainCanvas, dpi)}
      />
    </>
  );
};

export default DownloadMenu;
