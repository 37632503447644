import { useState, useEffect } from "react";
import { CiCircleMinus } from "react-icons/ci";
import { PiTextbox } from "react-icons/pi";
import {
  Toolbar,
  Box,
  Typography,
  List,
  IconButton,
  Tooltip,
  MenuItem,
  TextField,
  AppBar,
  Paper,
  Checkbox,
} from "@mui/material";
import {
  DrawerHeader,
  StyledAppBar,
  StyledDrawer,
  StyledListButton,
} from "../../Utils/styledComps";
import {
  AccountTreeOutlined,
  AddCircleOutline,
  Clear,
  ColorLensOutlined,
  Colorize,
  CreateOutlined,
  CropPortrait,
  DownloadOutlined,
  FileCopyOutlined,
  FileOpenOutlined,
  FilterBAndWOutlined,
  FolderCopyOutlined,
  FormatColorFill,
  Group,
  InfoOutlined,
  InsertDriveFileOutlined,
  Inventory2Outlined,
  LocalLibrary,
  ManageSearchOutlined,
  MenuOpen,
  Monitor,
  PhotoSizeSelectActualOutlined,
  Print,
  RouteOutlined,
  SaveAsOutlined,
  SaveOutlined,
  ScatterPlotOutlined,
  Share,
  SimCardDownloadOutlined,
  Sort,
  Straight,
  SubscriptionsOutlined,
  TimelineOutlined,
  UploadFileOutlined,
  UploadOutlined,
  UTurnRightOutlined,
  TripOriginOutlined,
  TurnSlightLeftOutlined,
  BlurCircularOutlined,
  LocationSearchingOutlined,
  HighlightAltOutlined,
  BarChart,
  SignalCellular4BarOutlined,
  PieChartOutlineOutlined,
  Brush,
  LensBlurOutlined,
  GradientOutlined,
  TextFields,
  TextIncreaseOutlined,
  BorderColor,
  FormatTextdirectionLToROutlined,
  FormatAlignLeftOutlined,
  FormatAlignCenterOutlined,
  FormatAlignRightOutlined,
  FormatAlignJustifyOutlined,
  Title,
  StyleOutlined,
  FormatUnderlinedOutlined,
  FormatBoldOutlined,
  FormatItalicOutlined,
  MergeOutlined,
  SuperscriptOutlined,
  SubscriptOutlined,
  Forward,
  DoorBackOutlined,
  DoorBack,
  BorderBottomOutlined,
  BorderTopOutlined,
  CropRotateOutlined,
  Rotate90DegreesCcwOutlined,
  Rotate90DegreesCwOutlined,
  FlipOutlined,
  HorizontalRuleOutlined,
  VerticalShadesOutlined,
  PanToolOutlined,
  Lock,
  AlignHorizontalCenter,
  ContentCutOutlined,
  ContentCopyOutlined,
  ContentPasteGoOutlined,
  ContentPasteSharp,
  UndoOutlined,
  RedoOutlined,
  PlaylistRemoveOutlined,
  PlaylistAddCheckCircleOutlined,
  SelectAllOutlined,
  CropSquareOutlined,
  ControlPointDuplicateOutlined,
  CopyAll,
  ContentPasteSearchOutlined,
  Grid4x4Outlined,
  BookOnlineOutlined,
  StraightenOutlined,
  ZoomIn,
  ZoomOut,
  ZoomOutMap,
  JoinFull,
  Remove,
  RemoveCircleOutline,
  CallSplitOutlined,
  Close,
  Mouse,
  IndeterminateCheckBoxTwoTone,
  Pages,
  Add,
  ChatBubble,
  SquareSharp,
  StarHalf,
  Equalizer,
  Grid4x4,
  ZoomOutMapOutlined,
  ZoomOutMapTwoTone,
  BackHand,
} from "@mui/icons-material";
import { event } from "jquery";
// import { HexAlphaColorPicker, HexColorInput, HexColorPicker } from 'react-colorful';
// import ColorPicker from 'react-best-gradient-color-picker';
import { useDispatch } from 'react-redux';
import { fabric } from 'fabric';
import { BiBarChart, BiChalkboard, BiCylinder, BiDoughnutChart, BiLineChart, BiPieChart, BiScatterChart, BiSprayCan, BiSquareRounded } from "react-icons/bi";
import { TbLine, TbArrowBigRight, TbOvalVertical, TbArrowBigRightLinesFilled, TbArrowCurveRight, TbSTurnDown, TbRipple } from "react-icons/tb";
import { LuCandlestickChart, LuMinusCircle, LuPlusCircle, LuRectangleHorizontal, LuTriangleRight } from "react-icons/lu";
import { GrCube, GrGallery } from "react-icons/gr";
import {
  MdLinearScale,
  MdOutlineHexagon,
  MdOutlineRectangle,
  MdUTurnRight,
} from "react-icons/md";
import {
  FaArrowRight,
  FaArrowsUpDownLeftRight,
  FaRegCircle,
  FaCloud,
  FaRegMoon,
  FaRegSquareFull,
  FaStar,
  FaSun,
  FaCircleNodes,
  FaRegObjectUngroup,
  FaT,
} from "react-icons/fa6";
import { IoTabletLandscape, IoTriangleOutline } from "react-icons/io5";
import { IoIosResize } from "react-icons/io";
import {
  PiArrowsVerticalBold,
  PiArrowElbowDownRightFill,
  PiParallelogram,
  PiPolygon,
  PiChatTeardrop,
  PiBracketsSquare,
  PiBracketsCurly,
  PiArrowArcRight,
  PiArrowArcLeft,
  PiArrowBendUpRight,
} from "react-icons/pi";
import { CgShapeHalfCircle, CgShapeRhombus, CgSmile } from "react-icons/cg";
import {
  BsFacebook,
  BsGoogle,
  BsHeptagon,
  BsInstagram,
  BsLinkedin,
  BsOctagon,
  BsPlusCircle,
  BsTwitterX,
} from "react-icons/bs";

import { TbHexagonNumber9 } from "react-icons/tb";
import { AiOutlineNodeIndex, AiOutlineThunderbolt } from "react-icons/ai";
import DownloadMenu from "./SBComponents/DownloadMenu/downloadMenu";
import { mainMenus } from "../../Utils/Constants/constants";
import { setBackgroundColor } from "../../Redux/slice/canvasPropsSlice";
import CustomPathPopoverContent from "./SBComponents/pathMenu";
import CustomColorPopoverContent from "./SBComponents/colorMenu";
import CustomViewPopoverContent from "./SBComponents/viewMenu";
import CustomCombinePopoverContent from "./SBComponents/combineMenu";
import CustomTextPopoverContent from "./SBComponents/textMenu";
import CustomAlignmentPopoverContent from "./SBComponents/alignmentMenu";
import CustomClipboardPopoverContent from "./SBComponents/clipboardMenu";
import CustomShapesPopoverContent from "./SBComponents/shapesMenu";
import CustomFilePopoverContent from "./SBComponents/fileMenu";
import CustomHomePopoverContent from "./SBComponents/homeMenu";
import CustomCanvasPopoverContent from "./SBComponents/canvasMenu";
import CustomPopover from "../../Utils/SideBarComps/customPopover";
import CustomSprayPopoverContent from "./SBComponents/sprayMenu";
import ColorPicker from "react-best-gradient-color-picker";
import { useNavigate } from "react-router-dom";

const Sidebar = ({
  chalkAndSponge,
  activeNodes,
  setActiveNodes,
  zoom,
  handlesConnected,
  setHandlesConnected,
  handleBendAlongPath,
  linearizeNode,
  onAddChart,
  sidebarRef,
  appBarRef1,
  appBarRef2,
  combineSelectedPathsExclusion,
  combineSelectedPaths,
  combineSelectedObjectsIntersection,
  onAddCanvas,
  switchCanvas,
  combineSelectedObjectsFragmentation,
  changeOpacity,
  canvasImage,
  mainCanvas,
  onPredifenedSizeChange,
  onSizeChange,
  handleDrawingMode,
  fillColor,
  setOpacity,
  handleZoomToPage,
  opacity,
  combineSelectedObjectsDifference,
  onDownloadSVG,
  onAddTrapezium,
  onAddPolygon,
  pathSelect,
  setPathSelect,
  circularizeNode,
  convertObjectToPath,
  onPatternAlongPath,
  onAddLine,
  selectedStrokeColor,
  setSelectedStrokeColor,
  onAddArrow,
  addNodeAtMidpoint,
  onAddStar,
  areaOfSpray,
  setAreaOfSpray,
  numberOfCopies,
  setNumberOfCopies,
  onAddRectangle,
  removeNodes,
  onAddSquare,
  onAddCircle,
  onAddTriangle,
  onFreeDrawing,
  onSetColor,
  onEraser,
  onUploadSVG,
  size,
  originalVisibility,
  activeObj,
  spraySelectRef,
  handleChangeView,
  handleSpraying,
  changeOpacityOfObj,
  opacityObj,
  horizonalGuidesRef,
  verticalGuidesRef,
  showGuides,
  setShowGuides,
  showRuler,
  setShowRuler,
  rulerToggleClick,
  handleZoomIn,
  handleZoomOut,
  zoomSelection,
  containerDim,
  setContainerDim,
  activeCanvasIndex,
  canvasArray,
  setActiveObj,
}) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(
    () => console.log("mainCanvas in Sidebar:", mainCanvas),
    [mainCanvas]
  );
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [currentPopover, setCurrentPopover] = useState(null);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [tempSize, setTempSize] = useState({
    width: "",
    height: "",
    unit: "px",
  });
  const [activeButton, setActiveButton] = useState(null);
  var iconColor = 'white';
  const [fontFamily, setFontFamily] = useState('Times New Roman');
  const [fontColor, setFontColor] = useState('');
  const [fontSize, setFontSize] = useState(20);
  const [fontFamilies, setFontFamilies] = useState(
    [
      'Arial',
      'Arvo',
      'Bank Gothic',
      'Calibri',
      'Calisto MT',
      'Candara',
      'Century Gothic',
      'Gadugi',
      'Georgia',
      'Lato',
      'Lucida Console',
      'Lucida Sans Unicode',
      'MS Serif',
      'Tahoma',
      'Times New Roman',
      'Trebuchet MS',
      'Verdana'
    ]);

  const fontSizes = [...Array(65).keys()].map(i => i + 8);
  const [isManualEntry, setIsManualEntry] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const downloadFormats = [
    "png",
    "jpeg",
    "svg",
    "pdf",
    "tiff",
    "bitmap",
    "dpi",
  ];

  const handleDownloadClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleButtonClick = (callback, buttonName) => () => {
    if (buttonName == "download") {
      setAnchorEl(event.currentTarget);

      console.log("handleButtonClick: download: ", callback, buttonName);
      setActiveButton(buttonName);
      onDownloadSVG();
    } else {
      setActiveButton(buttonName);
      callback(event, buttonName);
    }
  };
  const handleUnitChange = (e) => {
    setTempSize({
      ...tempSize,
      unit: e.target.value,
    });
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setCurrentPopover(null);
    setPopoverAnchorEl(null);
    setSelectedFeature(null);
  };
  const addText = () => {
    const textbox = new fabric.Textbox('Enter your text here', {
      left: 50,
      top: 50,
      width: 200,
      fontSize: fontSize,
      class: 'textbox',
      fontFamily: fontFamily,
      fontWeight: '10',
      hasControls: true,
      hasBorders: true,
      selectable: true,
      class: 'textbox'
    });
    mainCanvas.add(textbox).setActiveObject(textbox);
    // setActiveObj([textbox]);
  }


  const handleFontUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fontURL = e.target.result;
        const fontName = file.name.split('.')[0];

        const newFontFace = new FontFace(fontName, `url(${fontURL})`);

        newFontFace.load().then((loadedFont) => {
          document.fonts.add(loadedFont);

          // Add the custom font to the font family list
          setFontFamilies((prevFonts) => [...prevFonts, fontName]);

          // If there's an active text object, apply the new font immediately
          const activeObject = canvas.getActiveObject();
          if (activeObject && activeObject.type === 'text') {
            activeObject.set({ fontFamily: fontName });
            canvas.renderAll();
          }
        }).catch((err) => {
          console.error("Font loading error:", err);
        });
      };

      reader.readAsDataURL(file);
    }
  };


  const handleFontChange = async (event) => {
    const selectedFont = event.target.value;
    console.log('handleFontChange:', selectedFont);
    setFontFamily(selectedFont);
    mainCanvas.getActiveObject().set('fontFamily', selectedFont);
    mainCanvas.requestRenderAll();
  };

  const handleFontColor = async (selectedFontColor) => {
    setFontColor(selectedFontColor);
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      activeObject.set('fill', selectedFontColor);
      activeObject.set('stroke', selectedFontColor);
      mainCanvas.requestRenderAll();
    }
  }

  const handleFontSize = (e) => {
    const selectedFontSize = e.target.value;
    setFontSize(selectedFontSize);
    const activeObject = mainCanvas.getActiveObject();

    if (activeObject) {
      activeObject.set('fontSize', selectedFontSize);
      mainCanvas.requestRenderAll();
    }
  }

  const handleManualEntryClick = () => {
    setIsManualEntry(true);
    setFontSize('8');
  };


  const handleUnderline = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === 'textbox') {
      activeObject.set('underline', !activeObject.get('underline'));
      mainCanvas.requestRenderAll();
    }
  };

  const handleBold = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === 'textbox') {
      activeObject.set('fontWeight', activeObject.get('fontWeight') === 'bold' ? 'normal' : 'bold');
      mainCanvas.requestRenderAll();
    }
  };

  const handleItalics = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === 'textbox') {
      activeObject.set('fontStyle', activeObject.get('fontStyle') === 'italic' ? 'normal' : 'italic');
      mainCanvas.requestRenderAll();
    }
  };

  const handleMergeCenter = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === 'textbox') {
      activeObject.set({ textAlign: 'center' });
      mainCanvas.requestRenderAll();
    }
  };

  const superscripts = [];

  const handleSuperscript = () => {
    const activeObject = mainCanvas.getActiveObject();

    if (activeObject && activeObject.type === 'textbox') {
      const { selectionStart, selectionEnd, text, fontSize } = activeObject;

      if (selectionStart !== selectionEnd) {
        const selectedText = text.slice(selectionStart, selectionEnd);
        const newText = text.slice(0, selectionStart) + text.slice(selectionEnd);
        activeObject.set({ text: newText });

        const originalFontSize = fontSize || 40;

        const superscript = new fabric.Text(selectedText, {
          fontSize: originalFontSize * 0.7, 
          left: activeObject.left + selectionStart * (originalFontSize * 0.5) + 4,
          top: activeObject.top - originalFontSize * 0.3, 
          fontFamily: activeObject.fontFamily,
          fill: activeObject.fill,
          selectable: false 
        });

        mainCanvas.add(superscript);
        superscripts.push(superscript); 
        mainCanvas.requestRenderAll();
      } else {
        console.log("No text selected for superscript.");
      }
    } else {
      console.log("No textbox selected.");
    }
  };

  document.addEventListener('keydown', (e) => {
    if ((e.key === 'Backspace' || e.key === 'Delete') && superscripts.length > 0) {
     
      const lastSuperscript = superscripts.pop();
      mainCanvas.remove(lastSuperscript);
      mainCanvas.requestRenderAll();
    }
  });


  const subscripts = []; 

  const handleSubscript = () => {
    const activeObject = mainCanvas.getActiveObject();

    if (activeObject && activeObject.type === 'textbox') {
      const { selectionStart, selectionEnd, text, fontSize } = activeObject;

      if (selectionStart !== selectionEnd) {
        const selectedText = text.slice(selectionStart, selectionEnd);
        const newText = text.slice(0, selectionStart) + text.slice(selectionEnd);
        activeObject.set({ text: newText });

        const originalFontSize = fontSize || 40;

        const subscript = new fabric.Text(selectedText, {
          fontSize: originalFontSize * 0.7, 
          left: activeObject.left + selectionStart * (originalFontSize * 0.5) + 1, 
          top: activeObject.top + originalFontSize * 0.7, 
          fontFamily: activeObject.fontFamily,
          fill: activeObject.fill,
          selectable: false 
        });

        mainCanvas.add(subscript);
        subscripts.push(subscript); 
        mainCanvas.requestRenderAll();
      } else {
        console.log("No text selected for subscript.");
      }
    } else {
      console.log("No textbox selected.");
    }
  };

  document.addEventListener('keydown', (e) => {
    if ((e.key === 'Backspace' || e.key === 'Delete') && subscripts.length > 0) {
      const lastSubscript = subscripts.pop();
      mainCanvas.remove(lastSubscript);
      mainCanvas.requestRenderAll();
    }
  });


  const handleTextAlignLeft = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === 'textbox') {
      activeObject.set('textAlign', 'left');
      mainCanvas.requestRenderAll();
    }
  };

  const handleTextAlignCenter = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === 'textbox') {
      activeObject.set('textAlign', 'center');
      mainCanvas.requestRenderAll();
    }
  };

  const handleTextAlignRight = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === 'textbox') {
      activeObject.set('textAlign', 'right');
      mainCanvas.requestRenderAll();
    }
  };

  const handleTextAlignJustified = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === 'textbox') {
      activeObject.set('textAlign', 'justify');
      mainCanvas.requestRenderAll();
    }
  };

  const handleUpperCase = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === 'textbox') {
      activeObject.set('text', activeObject.text.toUpperCase());
      mainCanvas.requestRenderAll();
    }
  };

  const handleLowerCase = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === 'textbox') {
      activeObject.set('text', activeObject.text.toLowerCase());
      mainCanvas.requestRenderAll();
    }
  };

  const handleSentenceCase = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === 'textbox') {
      const sentenceCased = activeObject.text.charAt(0).toUpperCase() + activeObject.text.slice(1).toLowerCase();
      activeObject.set('text', sentenceCased);
      mainCanvas.requestRenderAll();
    }
  };

  const handleCapitalizeEachWord = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === 'textbox') {
      const capitalized = activeObject.text.replace(/\b\w/g, (char) => char.toUpperCase());
      activeObject.set('text', capitalized);
      mainCanvas.requestRenderAll();
    }
  };


  const handleCut = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      mainCanvas.remove(activeObject);
      mainCanvas.discardActiveObject();
      mainCanvas.requestRenderAll();
    }
  };

  let copiedObject = null;

  const handleCopyShape = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      activeObject.clone((clonedObject) => {
        copiedObject = clonedObject;
        console.log("Object copied:", copiedObject);
      });
    }
  };

  const handlePasteShape = () => {
    if (copiedObject) {
      copiedObject.clone((clonedClone) => {
        clonedClone.set({
          left: clonedClone.left + 10,
          top: clonedClone.top + 10,
          evented: true,
        });
        mainCanvas.add(clonedClone);
        mainCanvas.setActiveObject(clonedClone);
        mainCanvas.requestRenderAll();
      });
    }
  };

  const handleDuplicate = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      activeObject.clone((clonedObject) => {
        clonedObject.set({
          left: activeObject.left + 10,
          top: activeObject.top + 10,
          evented: true,
        });

        mainCanvas.add(clonedObject);
        mainCanvas.setActiveObject(clonedObject);
        mainCanvas.requestRenderAll();
      });
    }
  };


  const handlePasteAsPng = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      const id = activeObject.id;
      const dataUrl = activeObject.toDataURL({ format: 'png' });

      createImageFromDataURL(dataUrl, activeObject, id);
    } else {
      console.error('No active object to paste as PNG.');
    }
  };

  const handlePasteAsJpeg = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      const id = activeObject.id;
      const dataUrl = activeObject.toDataURL({ format: 'jpeg' });

      createImageFromDataURL(dataUrl, activeObject, id);
    } else {
      console.error('No active object to paste as JPEG.');
    }
  };

  const handlePasteAsBitmap = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      const id = activeObject.id;
      const dataUrl = activeObject.toDataURL({ format: 'png' });

      createImageFromDataURL(dataUrl, activeObject, id);
    } else {
      console.error('No active object to paste as Bitmap.');
    }
  };

  const handlePasteAsSvg = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      const id = activeObject.id;
      const svgData = activeObject.toSVG();
      const blob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
      const dataUrl = URL.createObjectURL(blob);

      createImageFromDataURL(dataUrl, activeObject, id);
    } else {
      console.error('No active object to paste as SVG.');
    }
  };

  const createImageFromDataURL = (dataUrl, activeObject, id) => {
    const imgElement = new Image();
    imgElement.src = dataUrl;

    imgElement.onload = () => {
      const imgInstance = new fabric.Image(imgElement, {
        left: activeObject.left + 20,
        top: activeObject.top + 20,
        scaleX: activeObject.scaleX,
        scaleY: activeObject.scaleY,
      });

      mainCanvas.add(imgInstance);
      mainCanvas.setActiveObject(imgInstance);
      mainCanvas.requestRenderAll();

      clonedObjects[id] = imgInstance;
    };
  };


  const clonedObjects = [];

  const cloneObjectWithSameId = () => {
    const originalObject = mainCanvas.getActiveObject();

    if (!originalObject) {
      console.error('No active object to clone.');
      return;
    }

    originalObject.clone((clonedObject) => {
      clonedObject.set('id', originalObject.id);
      clonedObject.set('class', 'cloned');
      clonedObject.set({
        left: originalObject.left + 50,
        top: originalObject.top + 50
      });

      mainCanvas.add(clonedObject);
      clonedObjects.push(clonedObject);
      const synchronizeProperties = (source, target) => {
        const originalSet = source.set.bind(source);
        source.set = function (key, value) {
          originalSet(key, value);
          if (key === 'fill') {
            target.set('fill', value);
          }
          if (key === 'stroke') {
            target.set('stroke', value);
          }

          if (key === 'width' || key === 'height' || key === 'scaleX' || key === 'scaleY') {
            target.set(key, value);
          }
        };

        target.set('stroke', source.stroke);
        target.set('fill', source.fill);
        target.set('width', source.width);
        target.set('height', source.height);
        target.set('scaleX', source.scaleX);
        target.set('scaleY', source.scaleY);
      };

      synchronizeProperties(originalObject, clonedObject);

      mainCanvas.renderAll();
      console.log('Cloned object with the same ID:', clonedObject);
    });
  };



  const selectAllObjects = () => {
    const allObjects = mainCanvas.getObjects();

    if (allObjects.length > 0) {
      const selection = new fabric.ActiveSelection(allObjects, {
        canvas: mainCanvas
      });

      mainCanvas.setActiveObject(selection);
      mainCanvas.requestRenderAll();
    }
  };

  function undo() {
    if (mainCanvas) {
      mainCanvas.undo();
    }

  };

  function redo() {
    mainCanvas.redo();

  };


  const groupSelectedObjects = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === 'activeSelection') {
      activeObject.toGroup();
      mainCanvas.requestRenderAll();
    }
  };


  const ungroupSelectedObjects = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === 'group') {
      activeObject.toActiveSelection();
      mainCanvas.requestRenderAll();
    }
  };

  const selectMostRecentObject = () => {

    const allObjects = mainCanvas.getObjects();

    if (allObjects.length > 0) {
      const mostRecentObject = allObjects[allObjects.length - 1];
      mainCanvas.discardActiveObject();
      mainCanvas.setActiveObject(mostRecentObject);
      mainCanvas.renderAll();
    } else {
      console.log("No objects on the canvas.");
    }
  }


  const handlePopoverClick = (event, popover) => {
    if (popover === "canvas") {
      setSelectedFeature("Size");
    }
    console.log(
      "handlePopoverClick: event.currentTarget, popover: ",
      event.currentTarget,
      popover
    );
    setPopoverAnchorEl(event.currentTarget);
    setCurrentPopover(popover);
    setActiveButton(popover);
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
    setCurrentPopover(null);
    setActiveButton(null);
    setSelectedFeature(null);
  };


  const handleBackButtonClick = () => {
    setSelectedFeature(null);
    setCurrentPopover(activeButton);
  };
  const isPopoverOpen = Boolean(popoverAnchorEl);
  const handlePatternAlongPathClick = () => {
    onPatternAlongPath();
    // setCurrentPopover("shapes");
    // setActiveButton("path");
  };
  const charts = [
    {
      class: "charts",
      label: "Pie Chart",
      icon: <BiPieChart />,
    },
    {
      class: "charts",
      label: "Histograms",
      icon: <BiBarChart />,
    },
    {
      class: "charts",
      label: "Venn Diagram",
      icon: <JoinFull />,
    },
    {
      class: "charts",
      label: "Scatter Plot",
      icon: <BiScatterChart />,
    },
    {
      class: "charts",
      label: "Line Chart",
      icon: <BiLineChart />,
    },
    {
      class: "charts",
      label: "Whisker Chart",
      icon: <LuCandlestickChart />,
    },
  ];
  const shapes = [
    {
      class: "basics",
      label: "Square",
      icon: <FaRegSquareFull />,
      onClick: onAddSquare,
    },
    {
      class: "basics",
      label: "Rounded Square",
      icon: <BiSquareRounded />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "roundedSquare"
        ),
    },
    {
      class: "basics",
      class: "basics",

      label: "Rectangle",
      icon: <MdOutlineRectangle />,
      onClick: onAddRectangle,
    },
    {
      class: "basics",
      class: "basics",

      label: "Rounded Rectangle",
      icon: <LuRectangleHorizontal />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "roundedRectangle"
        ),
    },
    {
      class: "basics",
      class: "basics",

      label: "Circle",
      icon: <FaRegCircle />,
      onClick: onAddCircle,
    },
    {
      class: "basics",
      class: "basics",

      label: "Oval",
      icon: <TbOvalVertical />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "oval"),
    },
    {
      class: "speech",
      class: "speech",

      label: "Speech Bubble 1",
      icon: <ChatBubble />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "roundedRectCallout"
        ),
    },
    {
      class: "basics",
      class: "basics",

      label: "Cylinder",
      icon: <BiCylinder />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "cylinder"
        ),
    },
    {
      class: "basics",
      class: "basics",

      label: "Cube",
      icon: <GrCube />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "cube"),
    },

    {
      class: "basics",
      class: "basics",

      label: "Triangle",
      icon: <IoTriangleOutline />,
      onClick: onAddTriangle,
    },
    {
      class: "basics",
      class: "basics",

      label: "Right Angle Triangle",
      icon: <LuTriangleRight />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "90Triangle"
        ),
    },
    {
      class: "basics",
      class: "basics",

      label: "Star",
      icon: <FaStar />,
      onClick: onAddStar,
    },
    {
      class: "lines",

      label: "Line",
      icon: <TbLine />,
      onClick: onAddLine,
    },

    {
      class: "lines",
      class: "lines",

      label: "Line Arrow",
      icon: <FaArrowRight />,
      onClick: onAddArrow,
    },
    {
      class: "lines",
      class: "lines",

      label: "Double Line Arrow",
      icon: <IoIosResize />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "doubleArrow"
        ),
    },
    {
      class: "arrows",
      class: "arrows",

      label: "Big Right Arrow",
      icon: <TbArrowBigRight />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "bigRightArrow"
        ),
    },
    {
      class: "arrows",
      class: "arrows",

      label: "Double Sided Arrow",
      icon: <PiArrowsVerticalBold />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "doubleSidedArrow"
        ),
    },
    {
      class: "lines",

      label: "Curved Line",
      icon: <img height={25} width={25} src="/elbow.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "curvedLine"
        ),
    },
    {
      class: "lines",

      label: "Curved Line With Arrow",
      icon: <img height={30} width={30} src="/elbow-single.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "curvedPolyArrow"
        ),
    },
    {
      class: "lines",

      label: "Curved Line With 2 Arrows",
      icon: <img height={25} width={25} src="/elbow-double.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "curvedPolyDoubleArrow"
        ),
    },

    {
      class: "lines",
      class: "lines",

      label: "Elbow Connector",
      icon: (
        <img height={25} width={25} src="/elbow-connector-svgrepo-com.svg" />
      ),
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "elbowConnectorLine"
        ),
    },
    {
      class: "lines",
      class: "lines",

      label: "Elbow Connector With Arrow",
      icon: <PiArrowElbowDownRightFill />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "elbowConnectorLineArrow"
        ),
    },
    {
      class: "lines",
      class: "lines",

      label: "Elbow Connector With Double Arrow",
      icon: <img height={25} width={25} src="/double-elbow.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "elbowConnectorLineDoubleArrow"
        ),
    },
    {
      class: "basics",
      class: "basics",

      label: "Single Snipped Rectangle",
      icon: <img height={25} width={25} src="/path (4).svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "singleSnippedRect"
        ),
    },
    {
      class: "basics",
      class: "basics",

      label: "Same Side Snipped Rectangle",
      icon: <img height={25} width={25} src="/double-snipped-rect.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "doubleSameSnippedRect"
        ),
    },
    {
      class: "basics",
      class: "basics",

      label: "Diagonal Snipped Rectangle",
      icon: <img height={25} width={25} src="/diagonal-snipped-rect.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "doubleDiagonalSnippedREct"
        ),
    },

    {
      class: "basics",
      class: "basics",

      label: "Rounded And Snipped Rectangle",
      icon: <img height={25} width={25} src="/snipped-rounded-rect.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "snippedAndRoundedRect"
        ),
    },
    {
      class: "basics",
      class: "basics",

      label: "Parallelogram",
      icon: <PiParallelogram />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "parallelogram"
        ),
    },
    {
      class: "basics",
      class: "basics",

      label: "Trapezium",
      icon: <img height={25} width={25} src="/trapezium.svg" />,
      onClick: onAddTrapezium,
    },
    {
      class: "basics",

      label: "Rounded Trapezium",
      icon: <img height={30} width={30} src="/trapezoid-svgrepo-com.png" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "roundedTrapezium"
        ),
    },
    {
      class: "basics",

      label: "Rhombus",
      icon: <CgShapeRhombus />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "rhombus"
        ),
    },
    {
      class: "basics",

      label: "Rounded Rhombus",
      icon: <img height={25} width={25} src="/shape-rhombus-svgrepo-com.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "roundedRhombus"
        ),
    },
    {
      class: "basics",

      label: "Polygon",
      icon: <PiPolygon />,
      onClick: onAddPolygon,
    },
    {
      class: "basics",

      label: "Hexagon",
      icon: <MdOutlineHexagon />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "hexagon"
        ),
    },
    {
      class: "basics",

      label: "Heptagon",
      icon: <BsHeptagon />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "heptagon"
        ),
    },
    {
      class: "basics",

      label: "Octagon",
      icon: <BsOctagon />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "octagon"
        ),
    },
    {
      class: "basics",

      label: "Nonagon",
      icon: <TbHexagonNumber9 />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "nonagon"
        ),
    },
    {
      class: "basics",

      label: "Decagon",
      icon: <img height={30} width={30} src="/decagon.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "decagon"
        ),
    },
    {
      class: "basics",

      label: "Hendecagon",
      icon: <img height={30} width={30} src="/hendagon.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "hendecagon"
        ),
    },
    {
      class: "basics",

      label: "Dodecagon",
      icon: <img height={30} width={30} src="/dodecagon.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "dodecagon"
        ),
    },
    {
      class: "specials",

      label: "Pie",
      icon: <img height={25} width={25} src="/pie.svg" />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "pie"),
    },
    {
      class: "specials",

      label: "Teardrop",
      icon: <PiChatTeardrop />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "teardrop"
        ),
    },
    {
      class: "specials",

      label: "Chord",
      icon: <SquareSharp />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "chord"),
    },
    {
      class: "basics",

      label: "Frame",
      icon: <img height={25} width={25} src="/frame.svg" />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "frame"),
    },
    {
      class: "basics",

      label: "Half Frame",
      icon: <img height={25} width={25} src="/half-frame.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "halfFrame"
        ),
    },
    {
      class: "basics",

      label: "L-Shape",
      icon: <img height={25} width={25} src="/l-shape.svg" />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "lShape"),
    },
    {
      class: "specials",

      label: "Smily",
      icon: <CgSmile />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "smily"),
    },
    {
      class: "specials",

      label: "Sun",
      icon: <FaSun />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "sun"),
    },
    {
      class: "basics",

      label: "Arc",
      icon: <CgShapeHalfCircle />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "bowArc"),
    },
    {
      class: "specials",

      label: "Cloud",
      icon: <FaCloud />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "cloud"),
    },
    {
      class: "specials",

      label: "Bevel",
      icon: <img height={25} width={25} src="/bevel.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "solidFrame"
        ),
    },
    {
      class: "specials",

      label: "Doughnut",
      icon: <BiDoughnutChart />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "doughnut"
        ),
    },
    {
      class: "specials",

      label: "Moon",
      icon: <FaRegMoon />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "crescent"
        ),
    },
    {
      class: "specials",

      label: "Thunderbolt",
      icon: <AiOutlineThunderbolt />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "thunderbolt"
        ),
    },
    {
      class: "specials",

      label: "No-Symbol",
      icon: <img height={25} width={25} src="/no-symbol.svg" />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "no"),
    },
    {
      class: "specials",

      label: "Square Brackets",
      icon: <PiBracketsSquare />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "squareBrackets"
        ),
    },
    {
      class: "specials",

      label: "Curly Brackets",
      icon: <PiBracketsCurly />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "curlyBrackets"
        ),
    },
    {
      class: "specials",
      label: "Folded Page",
      icon: <img height={25} width={25} src="/folded-paper.svg" />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "folded"),
    },
    {
      class: "arrows",
      label: "Curved Arrow",
      icon: <PiArrowArcRight />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "curvedArrow"
        ),
    },
    {
      class: "arrows",
      label: "Curved Arrow Left",
      icon: <PiArrowArcLeft />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "curvedArrowLeft"
        ),
    },
    // {
    //   class:"linesAndArrows",
    //   label:"Curved Arrow Top",
    //   icon:<DonutLargeSharp/>,
    //   onClick:() => handleDrawingMode(mainCanvas,fillColor,  selectedStrokeColor, "curvedArrowTop")
    // },
    // {
    //   class:"linesAndArrows",
    //   label:"Curved Arrow Bottom",
    //   icon:<DonutLargeSharp/>,
    //   onClick:() => handleDrawingMode(mainCanvas,fillColor,  selectedStrokeColor, "curvedArrowBottom")
    // },
    {
      class: "arrows",
      label: "Striped Arrow",
      icon: <TbArrowBigRightLinesFilled />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "stripedArrow"
        ),
    },
    {
      class: "arrows",
      label: "Notched Right Arrow",
      icon: <img height={25} width={25} src="/notched-arrow.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "notchedArrow"
        ),
    },
    {
      class: "arrows",
      label: "Right Callout Arrow",
      icon: <img height={25} width={25} src="/right-callout.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "rightCalloutArrow"
        ),
    },
    {
      class: "arrows",
      label: "Left-Right Callout Arrow",
      icon: <img height={25} width={25} src="/left-callout.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "leftRightCalloutArrow"
        ),
    },
    {
      class: "arrows",
      label: "Quad Callout Arrow",
      icon: <img height={25} width={25} src="/quad-callout.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "quadCalloutArrow"
        ),
    },
    {
      class: "arrows",
      label: "Chevron Arrow",
      icon: <img height={25} width={25} src="/chevron.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "chevronArrow"
        ),
    },
    {
      class: "arrows",
      label: "Quad Arrows",
      icon: <FaArrowsUpDownLeftRight />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "quadArrows"
        ),
    },
    {
      class: "arrows",
      label: "Triple Arrows",
      icon: <img height={25} width={25} src="/triple-arrows.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "tripleArrows"
        ),
    },
    {
      class: "arrows",
      label: "Curved Line Arrow",
      icon: <TbArrowCurveRight />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "curvedPolyArrow"
        ),
    },
    {
      class: "arrows",
      label: "Bent-Up Arrow",
      icon: <img height={25} width={25} src="/270.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "270Arrow"
        ),
    },
    {
      class: "arrows",
      label: "Left-Up Arrow",
      icon: <img height={25} width={25} src="/270-double.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "270DoubleArrow"
        ),
    },
    {
      class: "arrows",
      label: "U-Turn Arrow",
      icon: <MdUTurnRight />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "uTurnArrow"
        ),
    },
    {
      class: "arrows",
      class: "arrows",

      label: "Bent Arrow",
      icon: <PiArrowBendUpRight />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "rightAngleArrow"
        ),
    },
    {
      class: "banners",
      class: "banners",

      label: "Ribbon Banner 1",
      icon: <img height={25} width={25} src="/ribbon-banner-svgrepo-com.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "ribbonBanner"
        ),
    },
    {
      class: "banners",
      class: "banners",

      label: "Ribbon Banner 2",
      icon: (
        <img height={25} width={25} src="/ribbon-banner-2-svgrepo-com.svg" />
      ),
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "ribbonBanner2"
        ),
    },
    {
      class: "banners",
      label: "Wave Banner",
      icon: <img height={25} width={25} src="/flow-p-tape.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowPunchedTape"
        ),
    },
    {
      class: "equations",
      label: "Plus",
      icon: <Add />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "plus"),
    },
    ,
    {
      class: "equations",
      label: "Minus",
      icon: <Remove />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "minus"),
    },
    ,
    {
      class: "equations",
      label: "Multiply",
      icon: <Close />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "cross"),
    },
    ,
    {
      class: "equations",
      label: "Devide",
      icon: <StarHalf />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "devide"),
    },
    {
      class: "equations",
      label: "Equal",
      icon: <Equalizer />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "equals"),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Process",
      icon: <MdOutlineRectangle />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "rectangle"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Collate",
      icon: <img height={25} width={25} src="/collate.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowCollate"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Sort",
      icon: <img height={25} width={25} src="/flow-sort.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowSort"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Magnetic Sort",
      icon: <img height={25} width={25} src="/flow-mag-sort.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowMagSort"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Direct Access Storage",
      icon: <img height={25} width={25} src="/flow-das.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowDAS"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Summing Junction",
      icon: <img height={25} width={25} src="/flow-summing-junc.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowSummingJunction"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Manual Operation",
      icon: <img height={25} width={25} src="/flow-manual-op.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowManualOp"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Manual Input",
      icon: <img height={25} width={25} src="/flow-manual-inp.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowManualInp"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Alt Process",
      icon: <LuRectangleHorizontal />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "roundedRectangle"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Decision",
      icon: <img height={25} width={25} src="/decision.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowDecision"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Data",
      icon: <PiParallelogram />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "parallelogram"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Pre-Defined",
      icon: <img height={25} width={25} src="/flow-pre-defined.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowPreDefined"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Internal Storage",
      icon: <img height={25} width={25} src="/flow-internal.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowInternalStorage"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Document",
      icon: <img height={25} width={25} src="/flow-doc.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowDoc"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Multi Document",
      icon: <img height={25} width={25} src="/flow-multi-doc.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowMultiDoc"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Terminator",
      icon: <img height={25} width={25} src="/flow-terminator.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowTerminator"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Preparation",
      icon: <img height={25} width={25} src="/flow-prep.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowPreparation"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Connector",
      icon: <FaRegCircle />,
      onClick: () =>
        handleDrawingMode(mainCanvas, fillColor, selectedStrokeColor, "circle"),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Off Page Connector",
      icon: <img height={25} width={25} src="/flow-off-page.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowOffPConnector"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Card",
      icon: <img height={25} width={25} src="/flow-card.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowCard"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Punch Tape",
      icon: <img height={25} width={25} src="/flow-p-tape.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowPunchedTape"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Summing/Or",
      icon: <img height={25} width={25} src="/flow-summing.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowSumming"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Sequential Access Storage",
      icon: <img height={25} width={25} src="/flow-s-storage.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowSeqStorage"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Delay",
      icon: <img height={25} width={25} src="/flow-delay.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowDelay"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Extract",
      icon: <IoTriangleOutline />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "triangle"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Stored Data",
      icon: <img height={25} width={25} src="/flow-stored-data.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowStoredData"
        ),
    },
    {
      class: "flowChartShapes",
      label: "Flow: Display",
      icon: <img height={25} width={25} src="/flow-display.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "flowDisplay"
        ),
    },
    {
      class: "speech",
      label: "Round Speech Bubble",
      icon: <img height={25} width={25} src="/round-speech.svg" />,
      onClick: () =>
        handleDrawingMode(
          mainCanvas,
          fillColor,
          selectedStrokeColor,
          "roundSpeech"
        ),
    },

    {
      label: "Library Of Images",
      icon: <GrGallery />,
      onClick: () => console.log("Lib Clicked!"),
      class: "images",
    },
  ];
  const files = [
    {
      label: "New",
      icon: <AddCircleOutline />,
      onClick: () => console.log("New Canvas Clicked!"),
    },
    {
      label: "Open",
      icon: <FileOpenOutlined />,
      onClick: () => console.log("Open File Clicked!"),
    },
    {
      label: "Save",
      icon: <SaveOutlined />,
      onClick: () => console.log("Save Clicked!"),
    },
    {
      label: "Save As",
      icon: <SaveAsOutlined />,
      onClick: () => console.log("Save As Clicked!"),
    },
    {
      label: "Import",
      icon: <UploadOutlined />,
      onClick: () => document.getElementById("upload-svg-input").click(),
    },
    {
      label: "Export",
      icon: <DownloadOutlined />,
      onClick: onDownloadSVG,
    },
    {
      label: "Print",
      icon: <Print />,
      onClick: () => console.log("Print Clicked!"),
    },
    {
      label: "Account Information",
      icon: <InfoOutlined />,
      onClick: () => console.log("Account Information Clicked!"),
    },
    {
      label: "Subscription",
      icon: <SubscriptionsOutlined />,
      onClick: () => navigate("/subscription"), 
    },
    {
      label: "My Files",
      icon: <Inventory2Outlined />,
      onClick: () => console.log("My Files Clicked!"),
    },
    {
      label: "My Templates",
      icon: <FolderCopyOutlined />,
      onClick: () => console.log("My Templates Clicked!"),
    },
    {
      label: "Recent Files",
      icon: <InsertDriveFileOutlined />,
      onClick: () => console.log("Recent Files Clicked!"),
    },
    {
      label: "Version history",
      icon: <ManageSearchOutlined />,
      onClick: () => console.log("Version history Clicked!"),
    },
    {
      label: "Download Publication License",
      icon: <SimCardDownloadOutlined />,
      onClick: () => console.log("Download Publication License Clicked!"),
    },
    {
      label: "Duplicate",
      icon: <FileCopyOutlined />,
      onClick: () => console.log("Duplicate Clicked!"),
    },
    {
      label: "Share",
      icon: <Share />,
      onClick: () => console.log("Share Clicked!"),
    },
    {
      label: "Import (External)",
      icon: <UploadFileOutlined />,
      onClick: () => console.log("Import (External) Clicked!"),
    },
  ];
  const home = [
    {
      label: "Text",
      icon: <FaT />,
      onClick: () => console.log("Library Clicked!"),
    },
    {
      label: "Sort Icons",
      icon: <Sort />,
      onClick: () => console.log("Sort Clicked!"),
    },
    {
      label: "Background",
      icon: <Monitor />,
      onClick: () => console.log("BG Clicked!"),
    },
    {
      label: "Create Flow Chart",
      icon: <AccountTreeOutlined />,
      onClick: () => console.log("Flow Chart Clicked!"),
    },
    {
      label: "Community",
      icon: <Group />,
      onClick: () => console.log("Community Clicked!"),
    },
  ];
  const canvas = [
    {
      label: "Size",
      icon: <PhotoSizeSelectActualOutlined />,
      onClick: () => setSelectedFeature("Size"),
      children: [
        { label: "px X px" },
        { label: "cm X cm" },
        { label: "mm X mm" },
        { label: "inch X inch" },
        { label: "feet X feet" },
        {
          label: "A5",
          onClick: () => onPredifenedSizeChange("A5"),
          icon: (
            <Typography fontSize={14} color={"inherit"}>
              A5
            </Typography>
          ),
        },

        {
          label: "A4",
          onClick: () => onPredifenedSizeChange("A4"),
          icon: (
            <Typography fontSize={14} color={"inherit"}>
              A4
            </Typography>
          ),
        },
        {
          label: "A3",
          onClick: () => onPredifenedSizeChange("A3"),
          icon: (
            <Typography fontSize={14} color={"inherit"}>
              A3
            </Typography>
          ),
        },
        {
          label: "A2",
          onClick: () => onPredifenedSizeChange("A2"),
          icon: (
            <Typography fontSize={14} color={"inherit"}>
              A2
            </Typography>
          ),
        },
      ],
    },
    {
      label: "Background",
      icon: <ColorLensOutlined />,
      onClick: () => {
        setCurrentPopover("color-picker");
      },
    },
    {
      label: "Grids",
      icon: <Grid4x4 />,
      onClick: () => {
        setCurrentPopover("canvas-opacity");
      },
    },
    {
      label: "Poster",
      icon: <CropPortrait />,
      onClick: () => {
        setCurrentPopover("poster");
      },
      children: [
        {
          label: "3X4",
          onClick: () => onPredifenedSizeChange("3X4"),
          icon: (
            <Typography fontSize={14} color={"inherit"}>
              3X4
            </Typography>
          ),
        },
        {
          label: "4X3",
          onClick: () => onPredifenedSizeChange("4X3"),
          icon: (
            <Typography fontSize={14} color={"inherit"}>
              4X3
            </Typography>
          ),
        },
        {
          label: "54X40",
          onClick: () => onPredifenedSizeChange("54X40"),
          icon: (
            <Typography fontSize={14} color={"inherit"}>
              54X40
            </Typography>
          ),
        },
        {
          label: "40X54",
          onClick: () => onPredifenedSizeChange("40X54"),
          icon: (
            <Typography fontSize={14} color={"inherit"}>
              40X54
            </Typography>
          ),
        },
        {
          label: "48X36",
          onClick: () => onPredifenedSizeChange("48X36"),
          icon: (
            <Typography fontSize={14} color={"inherit"}>
              48X36
            </Typography>
          ),
        },
        {
          label: "36X48",
          onClick: () => onPredifenedSizeChange("36X48"),
          icon: (
            <Typography fontSize={14} color={"inherit"}>
              36X48
            </Typography>
          ),
        },
      ],
    },
    {
      label: "Banner",
      icon: <IoTabletLandscape />,
      onClick: () => {
        setCurrentPopover("poster");
      },
      children: [
        {
          label: "Full",
          onClick: () => onPredifenedSizeChange("fullBanner"),
          icon: (
            <Typography fontSize={12} color={"inherit"}>
              Full
            </Typography>
          ),
        },
        {
          label: "Half",
          onClick: () => onPredifenedSizeChange("halfBanner"),
          icon: (
            <Typography fontSize={12} color={"inherit"}>
              Half
            </Typography>
          ),
        },
        {
          label: "Skyscraper",
          onClick: () => onPredifenedSizeChange("skyscraper"),
          icon: (
            <Typography fontSize={12} color={"inherit"}>
              Skyscraper
            </Typography>
          ),
        },
        {
          label: "Wide",
          onClick: () => onPredifenedSizeChange("wideSkyscraper"),
          icon: (
            <Typography fontSize={12} color={"inherit"}>
              Wide
            </Typography>
          ),
        },
        {
          label: "Medium Banner",
          onClick: () => onPredifenedSizeChange("mediumBanner"),
          icon: (
            <Typography fontSize={12} color={"inherit"}>
              Medium Banner
            </Typography>
          ),
        },
        {
          label: "Leaderboard",
          onClick: () => onPredifenedSizeChange("leaderboard"),
          icon: (
            <Typography fontSize={12} color={"inherit"}>
              Leaderboard
            </Typography>
          ),
        },
        {
          label: "Half Page",
          onClick: () => onPredifenedSizeChange("halfPage"),
          icon: (
            <Typography fontSize={12} color={"inherit"}>
              Half Page
            </Typography>
          ),
        },
        {
          label: "Large Leaderboard",
          onClick: () => onPredifenedSizeChange("largeLeaderboard"),
          icon: (
            <Typography fontSize={12} color={"inherit"}>
              Wide
            </Typography>
          ),
        },
        {
          label: "Billboard",
          onClick: () => onPredifenedSizeChange("billboard"),
          icon: (
            <Typography fontSize={12} color={"inherit"}>
              Billboard
            </Typography>
          ),
        },
        {
          label: "Large Rectangle",
          onClick: () => onPredifenedSizeChange("largeRectangle"),
          icon: (
            <Typography fontSize={12} color={"inherit"}>
              Large Rectangle
            </Typography>
          ),
        },
        {
          label: "Small Square",
          onClick: () => onPredifenedSizeChange("smallSquare"),
          icon: (
            <Typography fontSize={12} color={"inherit"}>
              Small Square
            </Typography>
          ),
        },
        {
          label: "Square",
          onClick: () => onPredifenedSizeChange("square"),
          icon: (
            <Typography fontSize={12} color={"inherit"}>
              Square
            </Typography>
          ),
        },
        {
          label: "Vertical Banner",
          onClick: () => onPredifenedSizeChange("verticalBanner"),
          icon: (
            <Typography fontSize={12} color={"inherit"}>
              Vertical Banner
            </Typography>
          ),
        },
        {
          label: "Portrait",
          onClick: () => onPredifenedSizeChange("portrait"),
          icon: (
            <Typography fontSize={12} color={"inherit"}>
              Portrait
            </Typography>
          ),
        },
      ],
    },
    {
      label: "Social Media Posts",
      icon: <BsGoogle />,
      onClick: () => {
        setCurrentPopover("banner");
      },
      children: [
        {
          label: "LinkedIn",
          onClick: () => onPredifenedSizeChange("linkedIn"),
          icon: <BsLinkedin />,
        },
        {
          label: "Instagram",
          onClick: () => onPredifenedSizeChange("instagram"),
          icon: <BsInstagram />,
        },
        {
          label: "Facebook",
          onClick: () => onPredifenedSizeChange("facebook"),
          icon: <BsFacebook />,
        },
        {
          label: "X",
          onClick: () => onPredifenedSizeChange("wideSkyscraper"),
          icon: <BsTwitterX />,
        },
      ],
    },
    {
      label: "Abstract",
      icon: <IndeterminateCheckBoxTwoTone />,
      onClick: () => console.log("Community Clicked!"),
    },
    {
      label: "Pages",
      icon: <Pages />,
      onClick: () => console.log("Community Clicked!"),
    },
  ];
  const path = [
    {
      label: "Patern Along Path",
      icon: <TimelineOutlined />,
      onClick: handlePatternAlongPathClick,
    },
    {
      label: "Scatter Along Path",
      icon: <ScatterPlotOutlined />,
      onClick: () => console.log("Color Clicked!"),
    },
    {
      label: "Object to Path",
      icon: <RouteOutlined />,
      onClick: () => console.log("BG Clicked!"),
    },
    {
      label: "Stroke to Path",
      icon: <Straight />,
      onClick: () => console.log("Flow Chart Clicked!"),
    },
    {
      label: "Path Effects",
      icon: <FilterBAndWOutlined />,
      children: [
        { label: "Circularize Nodes", icon: <TripOriginOutlined /> },
        { label: "Curve Along Path", icon: <UTurnRightOutlined /> },
        { label: "Bend Along Path", icon: <TurnSlightLeftOutlined /> },
      ],
      onClick: () => setSelectedFeature("Path Effects"),
    },
    {
      label: "Nodes",
      icon: <img height={25} width={25} src="/node-hardware-svgrepo-com.svg" />,
      children: [
        { label: "Select Nodes", icon: <HighlightAltOutlined /> },
        {
          label: "Nodes",
          icon: <img height={25} width={25} src="/node-hardware-svgrepo-com.svg" />,
          children: [
            { label: "Select Nodes", icon: <HighlightAltOutlined /> },

            { label: "Circularize All Nodes", icon: <TripOriginOutlined /> },
            { label: "Circularize Fixed Nodes", icon: <BlurCircularOutlined /> },
            { label: "Point By Point Edit", icon: <LocationSearchingOutlined /> },
          ],
        }
      ],
      onClick: () => setSelectedFeature("Nodes"),
    },

    {
      label: "Graphs",
      icon: <BarChart />,
      children: [
        { label: "Bar Chart", icon: <SignalCellular4BarOutlined /> },

        { label: "Pie Chart", icon: <PieChartOutlineOutlined /> },
        {
          label: "Histogram",
          icon: (
            <img
              height={25}
              width={25}
              src="/histogram-show-all-svgrepo-com.svg"
            />
          ),
        },
      ],
      onClick: () => setSelectedFeature("Graphs"),
    },
    {
      label: "Effects",
      icon: <LensBlurOutlined />,
      children: [
        {
          label: "Ripple",
          icon: <img height={25} width={25} src="/ripple-svgrepo-com.svg" />,
        },
        { label: "Chalk And Brush", icon: <Brush /> },
      ],
      onClick: () => setSelectedFeature("Effects"),
    },
  ];
  const color = [
    {
      label: "Fill Color",
      icon: <FormatColorFill />,
      onClick: () => document.getElementById("color-picker").click(),
    },
    {
      label: "Stroke Color",
      icon: <Colorize />,
      onClick: () => console.log("Color Clicked!"),
    },
    {
      label: "Opacity",
      icon: <GradientOutlined />,
      onClick: () => console.log("BG Clicked!"),
    },
  ];
  const text = [
    {
      label: "Text Box",
      icon: <PiTextbox />,
      onClick: () => { addText() }
    },
    {
      label: "Font Family",
      icon: <TextFields />,
      children: [
        {
          label: 'Font Family',
          icon: (
            <>
              <TextField
                label="Enter Font Family"
                value={fontFamily}
                onChange={(event) => {
                  if (event.target.value === 'upload') {
                    document.getElementById('font-upload-input').click();
                  } else {
                    handleFontChange(event);
                  }
                }}
                fullWidth
                select
                sx={{ width: '100%' }}
              >
                {fontFamilies.map((font) => (
                  <MenuItem key={font} value={font}>
                    {font}
                  </MenuItem>

                ))}
                <MenuItem value="upload">
                  Upload Font
                </MenuItem>
              </TextField>
              <input
                type="file"
                accept=".ttf,.woff,.woff2,.otf"
                hidden
                id="font-upload-input"
                onChange={handleFontUpload}
              />
            </>
          ),
        },
      ],
      onClick: () => setSelectedFeature("Font Family")
    },
    {
      label: "Font Size",
      icon: <TextIncreaseOutlined />,
      children: [
        {
          label: "Input",
          icon: (
            <>
              {isManualEntry ? (
                <TextField
                  label="Enter Font Size"
                  fullWidth
                  value={fontSize}
                  onChange={handleFontSize}
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    min: 2, // Prevent values less than 2 in manual entry
                  }}
                  sx={{ marginBottom: '16px' }}
                  onBlur={() => setIsManualEntry(false)} // Exit manual entry on blur
                />
              ) : (
                <TextField
                  label="Select Font Size"
                  fullWidth
                  select
                  value={fontSize}
                  onChange={handleFontSize}
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    min: 2, // Prevent values less than 2 in numeric input
                  }}
                  sx={{ marginBottom: '16px' }}
                >
                  {fontSizes.map((font) => (
                    <MenuItem key={font} value={font}>
                      {font}
                    </MenuItem>
                  ))}
                  <MenuItem onClick={handleManualEntryClick}>
                    Manual Entry
                  </MenuItem>
                </TextField>
              )}
            </>
          ),
        },
      ],
      onClick: () => setSelectedFeature("Font Size")
    },
    {
      label: "Font Color",
      icon: <BorderColor />,
      children: [
        {
          label: 'Font color',
          icon: (
            <div style={{ display: 'flex', alignItems: 'center', padding: '4px' }}>
              <ColorPicker
                hideEyeDrop
                style={{
                  borderRadius: '10px',
                  padding: '1px',
                  width: '36px',
                  height: '36px',
                  cursor: 'pointer'
                }}
                value={fontColor} onChange={(color) => { handleFontColor(color) }}
              />
            </div>
          )
        }
      ],
      onClick: () => setSelectedFeature("Font Color")
    },
    {
      label: "Text Alignment",
      icon: <FormatTextdirectionLToROutlined />,
      children: [
        { label: "Left", icon: <FormatAlignLeftOutlined />, onClick: handleTextAlignLeft },
        { label: "Center", icon: <FormatAlignCenterOutlined />, onClick: handleTextAlignCenter },
        { label: "Right", icon: <FormatAlignRightOutlined />, onClick: handleTextAlignRight },
        { label: "Justified", icon: <FormatAlignJustifyOutlined />, onClick: handleTextAlignJustified },
      ],
      onClick: () => setSelectedFeature("Text Alignment")
    },

    {
      label: 'Styles',
      icon: <StyleOutlined />,
      children: [
        { label: "Underline", icon: <FormatUnderlinedOutlined />, onClick: handleUnderline },
        { label: "Bold", icon: <FormatBoldOutlined />, onClick: handleBold },
        { label: "Italics", icon: <FormatItalicOutlined />, onClick: handleItalics },
        { label: "Merge & Center", icon: <MergeOutlined />, onClick: handleMergeCenter },
        { label: "Superscript (30%)", icon: <SuperscriptOutlined />, onClick: handleSuperscript },
        { label: "Subscript (30%)", icon: <SubscriptOutlined />, onClick: handleSubscript }
      ],
      onClick: () => setSelectedFeature("Styles"),
    },
    {
      label: "Text Cases",
      icon: <img height={25} width={25} src="/text-change-case-svgrepo-com.svg" />,
      children: [
        { label: "Upper Case", icon: <img height={25} width={25} src="/uppercase-or-lowercase-button-svgrepo-com.svg" />, onClick: handleUpperCase },
        { label: "Lower Case", icon: <img height={25} width={25} src="/uppercase-or-lowercase-options-button-svgrepo-com.svg" />, onClick: handleLowerCase },
        { label: "Sentence Case", icon: <img height={25} width={25} src="/paragraph-capital-svgrepo-com.svg" />, onClick: handleSentenceCase },
        { label: "Capitalize Each Word", icon: <img height={25} width={25} src="/capitalize-ui-svgrepo-com.svg" />, onClick: handleCapitalizeEachWord }
      ],
      onClick: () => setSelectedFeature("Text Cases"),
    },
  ]


  const download = [
    "png",
    "tiff",
    "bitmap",
    "jpeg",
    "svg",
    "pdf",
    "dpi defined",
  ];
  const alignment = [
    {
      label: "Forward",
      icon: <Forward />,
      onClick: () => console.log("Alignment"),
    },
    {
      label: "Backward",
      icon: <DoorBackOutlined />,
      onClick: () => console.log("Alignment"),
    },
    {
      label: "Front",
      icon: <Forward />,
      onClick: () => console.log("Alignment"),
    },
    {
      label: "Back",
      icon: <DoorBack />,
      onClick: () => console.log("Alignment"),
    },
    {
      label: "Lock",
      icon: <Lock />,
      onClick: () => console.log("Alignment"),
    },
    {
      label: "Alignment",
      icon: <FormatTextdirectionLToROutlined />,
      children: [
        {
          label: "Left",
          icon: <FormatAlignLeftOutlined />,
          onClick: () => console.log("Alignment"),
        },
        {
          label: "Center",
          icon: <FormatAlignCenterOutlined />,
          onClick: () => console.log("Alignment"),
        },
        {
          label: "Right",
          icon: <FormatAlignRightOutlined />,
          onClick: () => console.log("Alignment"),
        },
        {
          label: "Middle",
          icon: <FormatAlignJustifyOutlined />,
          onClick: () => console.log("Alignment"),
        },
        {
          label: "Bottom",
          icon: <BorderBottomOutlined />,
          onClick: () => console.log("Alignment"),
        },
        {
          label: "Top",
          icon: <BorderTopOutlined />,
          onClick: () => console.log("Alignment"),
        },
      ],
      onClick: () => setSelectedFeature("Alignment"),
    },
    {
      label: "Rotation",
      icon: <CropRotateOutlined />,
      children: [
        { label: "Left 90", icon: <Rotate90DegreesCcwOutlined /> },
        { label: "Right 90", icon: <Rotate90DegreesCwOutlined /> },
        { label: "Free Rotation", icon: <CropRotateOutlined /> },
        { label: "Flip Horizontal", icon: <FlipOutlined /> },
        { label: "Flip Vertical", icon: <FlipOutlined /> },
      ],
      onClick: () => setSelectedFeature("Alignment"),
    },
    {
      label: "Distribute",
      icon: <ScatterPlotOutlined />,
      children: [
        { label: "Distribute Horizontally", icon: <HorizontalRuleOutlined /> },
        { label: "Distribute Vertically", icon: <VerticalShadesOutlined /> },
        { label: "Drag & Drop", icon: <PanToolOutlined /> },
      ],
      onClick: () => setSelectedFeature("Distribute"),
    },
  ];
  const clipboard = [
    {
      label: "Cut",
      icon: <ContentCutOutlined />,
      onClick: () => handleCut()
    },
    {
      label: "Copy",
      icon: <ContentCopyOutlined />,
      onClick: () => handleCopyShape()
    },
    {
      label: "Paste",
      icon: <ContentPasteGoOutlined />,
      onClick: () => handlePasteShape()
    },
    {
      label: "Paste Special",
      icon: <ContentPasteSharp />,
      children: [
        { label: "Paste as PNG", icon: <span style={{ fontSize: '14px' }}>PNG</span>, onClick: () => handlePasteAsPng() },
        { label: "Paste as JPEG", icon: <span style={{ fontSize: '14px' }}>JPEG</span>, onClick: () => handlePasteAsJpeg() },
        { label: "Paste as BITMAP", icon: <span style={{ fontSize: '14px' }}>BITMAP</span>, onClick: () => handlePasteAsBitmap() },
        { label: "Paste as SVG", icon: <span style={{ fontSize: '14px' }}>SVG</span>, onClick: () => handlePasteAsSvg() },
      ],
      onClick: () => setSelectedFeature("Paste Special")
    },
    {
      label: "Undo",
      icon: <UndoOutlined />,
      onClick: () => undo()
    },
    {
      label: "Redo",
      icon: <RedoOutlined />,
      onClick: () => redo()
    },
    {
      label: "Group",
      icon: <PlaylistAddCheckCircleOutlined />,
      onClick: () => groupSelectedObjects()
    },
    {
      label: "Ungroup",
      icon: <PlaylistRemoveOutlined />,
      onClick: () => ungroupSelectedObjects()
    },
    {
      label: "Select",
      icon: <CropSquareOutlined />,
      onClick: () => selectMostRecentObject()
    },
    {
      label: "Select All",
      icon: <SelectAllOutlined />,
      onClick: () => selectAllObjects()
    },
    {
      label: "Duplicate",
      icon: <ControlPointDuplicateOutlined />,
      onClick: () => handleDuplicate()
    },
    {
      label: "Clone",
      icon: <CopyAll />,
      onClick: () => cloneObjectWithSameId()
    },
  ]

  const view = [
    {
      label: "Grid",
      icon: <Grid4x4Outlined />,
      onClick: () => console.log("Size Clicked!"),
    },
    {
      label: "Guides",
      icon: <BookOnlineOutlined />,
      onClick: () => console.log("Color Clicked!"),
    },
    {
      label: "Ruler",
      icon: <StraightenOutlined />,
      onClick: () => {
        rulerToggleClick();
      },
    },
    {
      label: "Zoom In",
      icon: <ZoomIn />,
      // onClick: () => console.log("Flow Chart Zoom In +++"),
      onClick: () => {
        handleZoomIn();
        console.log("Flow Chart Zoom In +++");
      },
    },
    {
      label: "Zoom Out",
      icon: <ZoomOut />,
      onClick: () => {
        handleZoomOut();
        console.log("Flow Chart Zoom Out---");
      },
      // onClick: () => console.log("Flow Chart Zoom Out---"),
    },
    {
      label: "Zoom To Page",
      icon: <ZoomOutMap />,
      onClick: () => {
        handleZoomToPage();
      },
      // onClick: () => console.log("Zoom To Page"),
    },
    {
      label: "Zoom To Selection",
      icon: <ZoomOutMapTwoTone />,
      onClick: () => {
        // handleFullPage();
        zoomSelection();
      },
      // onClick: () => console.log("Zoom To Page"),
    },
    {
      label: "Clone",
      icon: <CopyAll />,
      onClick: () => {
        cloneObjectWithSameId();
      },
    },
    {
      label: "Copy",
      icon: <ContentCopyOutlined />,
      onClick: () => {
        handleCopyShape();
      },
    },
    {
      label: "Cut",
      icon: <ContentCutOutlined />,
      onClick: () => {
        handleCut();
      },
    },
    {
      label: "Paste",
      icon: <ContentPasteGoOutlined />,
      onClick: () => {
        handlePasteShape();
      },
    },
    {
      label: "Undo",
      icon: <UndoOutlined />,
      onClick: () => {
        undo();
      },
    },
    {
      label: "Redo",
      icon: <RedoOutlined />,
      onClick: () => {
        redo();
      },
    },
  ];
  const combine = [
    {
      label: "Union",
      icon: <JoinFull />,
      onClick: combineSelectedPaths,
    },
    {
      label: "Intersection",
      icon: <Close />,
      onClick: combineSelectedObjectsIntersection,
    },


    {
      label: "Exclusion",
      icon: <RemoveCircleOutline />,
      onClick: combineSelectedPathsExclusion,
    },
    {
      label: "Fragment",
      icon: <CallSplitOutlined />,
      onClick: combineSelectedObjectsFragmentation,
    },
    {
      label: "Subtraction",
      icon: <ContentCutOutlined />,
      onClick: combineSelectedObjectsDifference,
    },
  ];

  const renderPopoverContent = () => {
    switch (currentPopover) {
      case "spray":
        return (
          <CustomSprayPopoverContent
            mainCanvas={mainCanvas}
            spraySelectRef={spraySelectRef}
            handlePopoverClose={handlePopoverClick}
            handleSpraying={handleSpraying}
            activeObj={activeObj}
            areaOfSpray={areaOfSpray}
            setAreaOfSpray={setAreaOfSpray}
            numberOfCopies={numberOfCopies}
            setNumberOfCopies={setNumberOfCopies}
          />
        );
      case "shapes":
        return (
          <CustomShapesPopoverContent
            shapes={shapes}
            onAddChart={onAddChart}
            currentPopover={currentPopover}
            charts={charts}
            selectedFeature={selectedFeature}
            setSelectedFeature={setSelectedFeature}
            handlePopoverClose={handlePopoverClose}
          />
        );
      case "path":
        return (
          <CustomPathPopoverContent
            selectedFeature={selectedFeature}
            handleBackButtonClick={handleBackButtonClick}
            handlePopoverClose={handlePopoverClick}
            path={path}
          />
        );
      case "color":
        return (
          <CustomColorPopoverContent
            currentPopover={currentPopover}
            fillColor={fillColor}
            onSetColor={onSetColor}
            setSelectedFeature={setSelectedFeature}
            selectedFeature={selectedFeature}
            selectedStrokeColor={selectedStrokeColor}
            setSelectedStrokeColor={setSelectedStrokeColor}
            handleBackButtonClick={handleBackButtonClick}
            handlePopoverClose={handlePopoverClick}
            color={color}
            handleSpraying={handleSpraying}
            opacityObj={opacityObj}
            changeOpacityOfObj={changeOpacityOfObj}
          />
        );
      case "view":
        return (
          <CustomViewPopoverContent
            selectedFeature={selectedFeature}
            handleBackButtonClick={handleBackButtonClick}
            handlePopoverClose={handlePopoverClick}
            view={view}
            mainCanvas={mainCanvas}
            onAddCanvas={onAddCanvas}
            activeCanvasIndex={activeCanvasIndex}
            switchCanvas={switchCanvas}
            canvasArray={canvasArray}
            horizonalGuidesRef={horizonalGuidesRef}
            verticalGuidesRef={verticalGuidesRef}
            showGuides={showGuides}
            setShowGuides={setShowGuides}
            showRuler={showRuler}
            setShowRuler={setShowRuler}
            containerDim={containerDim}
            setContainerDim={setContainerDim}

          />
        );
      case "combinations":
        return (
          <CustomCombinePopoverContent
            selectedFeature={selectedFeature}
            handleBackButtonClick={handleBackButtonClick}
            handlePopoverClose={handlePopoverClick}
            combine={combine}
            originalVisibility={originalVisibility}
            handleChangeView={handleChangeView}
          />
        );
      case "text":
        return (
          <CustomTextPopoverContent
            selectedFeature={selectedFeature}
            handleBackButtonClick={handleBackButtonClick}
            handlePopoverClose={handlePopoverClick}
            text={text}
          />
        );
      case "alignment":
        return (
          <CustomAlignmentPopoverContent
            selectedFeature={selectedFeature}
            handleBackButtonClick={handleBackButtonClick}
            handlePopoverClose={handlePopoverClick}
            alignment={alignment}
          />
        );
      case "clipboard":
        return (
          <CustomClipboardPopoverContent
            selectedFeature={selectedFeature}
            handleBackButtonClick={handleBackButtonClick}
            handlePopoverClose={handlePopoverClick}
            clipboard={clipboard}
          />
        );
      case "file":
        return (
          <CustomFilePopoverContent
            files={files}
            handlePopoverClose={handlePopoverClick}
          />
        );
      case "home":
        return (
          <CustomHomePopoverContent
            home={home}
            handlePopoverClose={handlePopoverClose}
          />
        );
      case "canvas":
        return (
          <CustomCanvasPopoverContent
            selectedFeature={selectedFeature}
            mainCanvas={mainCanvas}
            setSelectedFeature={setSelectedFeature}
            currentPopover={currentPopover}
            handlePopoverClose={handlePopoverClose}
            canvas={canvas}
            size={size}
            changeOpacity={changeOpacity}
            tempSize={tempSize}
            setTempSize={setTempSize}
            handleUnitChange={handleUnitChange}
            onSizeChange={onSizeChange}
            onPredifinedSizeChange={onPredifenedSizeChange}
            opacity={opacity}
            setOpacity={setOpacity}
            changeBackground={changeBackground}
            canvasColor={canvasColor}
            onAddCanvas={onAddCanvas}
            canvasImage={canvasImage}
            switchCanvas={switchCanvas}
          />
        );

      default:
        return null;
    }
  };

  const [canvasColor, setCanvasColor] = useState();
  const changeBackground = (e) => {
    if (mainCanvas) {
      setCanvasColor(e);
      if (!e.includes("gradient")) {
        // Solid color
        mainCanvas.setBackgroundImage(null);
        mainCanvas.backgroundColor = e;
        mainCanvas.renderAll();
        console.log("bgColor", mainCanvas, e);
      } else {
        // Gradient
        const linearGradientMatch = e.match(
          /linear-gradient\(([^,]+),\s*(.+)\)/
        );
        const radialGradientMatch = e.match(
          /radial-gradient\(([^,]+),\s*(.+)\)/
        );

        if (linearGradientMatch || radialGradientMatch) {
          let gradient;
          if (linearGradientMatch) {
            // Handle linear gradient
            const angle = linearGradientMatch[1].trim();
            const colorStopsString = linearGradientMatch[2].trim();
            const colorStopsArray = parseColorStops(colorStopsString);
            const coords = getGradientCoords(
              angle,
              mainCanvas.width,
              mainCanvas.height
            );

            gradient = new fabric.Gradient({
              type: "linear",
              gradientUnits: "pixels",
              coords: coords,
              colorStops: colorStopsArray,
            });
          } else if (radialGradientMatch) {
            // Handle radial gradient
            const colorStopsString = radialGradientMatch[2].trim();
            const colorStopsArray = parseColorStops(colorStopsString);

            gradient = new fabric.Gradient({
              type: "radial",
              gradientUnits: "pixels",
              coords: {
                x1: mainCanvas.width / 2,
                y1: mainCanvas.height / 2,
                r1: 0,
                x2: mainCanvas.width / 2,
                y2: mainCanvas.height / 2,
                r2: Math.max(mainCanvas.width, mainCanvas.height) / 2,
              },
              colorStops: colorStopsArray,
            });
          }

          mainCanvas.setBackgroundImage(null);

          mainCanvas.setBackgroundImage(null);
          mainCanvas.backgroundColor = gradient;
          mainCanvas.renderAll();
          console.log("bgColor: Applied gradient:", gradient);
        }
      }
    }
  };

  // Helper function to parse color stops
  const parseColorStops = (colorStopsString) => {
    return colorStopsString
      .split(/,(?![^(]*\))/)
      .map((stop) => {
        const parts = stop
          .trim()
          .match(/(rgba?\([^\)]+\)|#[0-9a-fA-F]+|rgb\([^\)]+\))\s*([\d.]+)%?/i);
        if (parts) {
          return {
            offset: parseFloat(parts[2]) / 100,
            color: parts[1].toLowerCase(), // Ensure color is in lowercase for compatibility
          };
        }
        return {
          offset: null,
          color: stop.trim(),
        };
      })
      .map((stop, index, array) => {
        if (isNaN(stop.offset)) {
          stop.offset = index / (array.length - 1);
        }
        return stop;
      });
  };

  // Function to calculate linear gradient coordinates based on angle
  const getGradientCoords = (angle, width, height) => {
    // Convert angle to radians
    const angleRad = (parseFloat(angle) * Math.PI) / 180;

    let x1, y1, x2, y2;

    x1 = width / 2 + (width / 2) * Math.cos(angleRad + Math.PI);
    y1 = height / 2 + (height / 2) * Math.sin(angleRad + Math.PI);
    x2 = width / 2 + (width / 2) * Math.cos(angleRad);
    y2 = height / 2 + (height / 2) * Math.sin(angleRad);

    return { x1, y1, x2, y2 };
  };

  // const getGradientCoords = (angle) => {
  //   switch (angle) {
  //     case 'to right':
  //     case '90deg':
  //       return { x1: 0, y1: 0, x2: mainCanvas.width, y2: 0 };
  //     case 'to left':
  //     case '270deg':
  //       return { x1: mainCanvas.width, y1: 0, x2: 0, y2: 0 };
  //     case 'to bottom':
  //     case '180deg':
  //       return { x1: 0, y1: 0, x2: 0, y2: mainCanvas.height };
  //     case 'to top':
  //     case '0deg':
  //       return { x1: 0, y1: mainCanvas.height, x2: 0, y2: 0 };
  //     case 'to top right':
  //     case '45deg':
  //       return { x1: 0, y1: mainCanvas.height, x2: mainCanvas.width, y2: 0 };
  //     case 'to bottom right':
  //     case '135deg':
  //       return { x1: 0, y1: 0, x2: mainCanvas.width, y2: mainCanvas.height };
  //     case 'to bottom left':
  //     case '225deg':
  //       return { x1: mainCanvas.width, y1: 0, x2: 0, y2: mainCanvas.height };
  //     case 'to top left':
  //     case '315deg':
  //       return { x1: mainCanvas.width, y1: mainCanvas.height, x2: 0, y2: 0 };
  //     default:
  //       return { x1: 0, y1: 0, x2: mainCanvas.width, y2: mainCanvas.height };
  //   }
  // };
  const handleNodeSelect = () => {
    if (pathSelect) {
      setPathSelect(false);
    } else {
      setPathSelect(true);
    }
    console.log("handleNodeSelect: ", pathSelect);
  };

  return (
    <div>
      <CustomPopover
        isOpen={isPopoverOpen}
        anchorEl={popoverAnchorEl}
        onClose={handlePopoverClose}
        currentPopover={currentPopover}
      >
        {renderPopoverContent()}
      </CustomPopover>


      <Paper sx={{ background: "#36454F" }}>
        <StyledAppBar ref={appBarRef1} elevation={0} position="fixed" open={open}>
          <Toolbar variant='dense' sx={{ justifyContent: "space-between" }}>
            <div style={{ display: "flex", gap: 10, alignItems: "center", justifyContent: "left" }}>

              <Typography variant="h6" align='right' noWrap>
                BioGraphix
              </Typography>

              <Tooltip arrow title="Download">
                <StyledListButton onClick={handleDownloadClick}>
                  <DownloadOutlined />
                </StyledListButton>
              </Tooltip>

              <DownloadMenu
                anchorEl={anchorEl}
                handleClose={handleCloseMenu}
                mainCanvas={mainCanvas}
                formats={downloadFormats}
                dpi={300}
              />

            </div>
            <div >
              <Tooltip arrow title="Upload">
                <StyledListButton sx={{ p: 0, left: 10 }} onClick={() => handleButtonClick(document.getElementById('upload-svg-input').click(), "upload")}>
                  <UploadOutlined />
                </StyledListButton>
              </Tooltip>
            </div>
          </Toolbar>
        </StyledAppBar>
        <AppBar ref={appBarRef2} sx={{
          mt: 6,
          height: "30px",
          // color:"black",
          background: "#36454F"
        }} position='fixed' open={open}>
          <Toolbar sx={{
            display: "flex",
            color: "#D3D3D3",
            justifyContent: "space-between",
            "&.MuiToolbar-root": {
              p: 0,
              pb: 3
            }
          }} variant="dense">
            <Box display={"flex"} sx={{ pl: 7 }}>

              <Tooltip arrow title="Color">
                <StyledListButton onClick={(event) => handlePopoverClick(event, 'color')}>
                  <FormatColorFill sx={{ fontSize: 20 }} />
                </StyledListButton>
              </Tooltip>
              <Tooltip arrow title="Spray Selected Object">
                <StyledListButton onClick={(event) => handlePopoverClick(event, 'spray')}>
                  <BiSprayCan sx={{ fontSize: 20 }} />
                </StyledListButton>
              </Tooltip>
              <Tooltip arrow title="Alignment">
                <StyledListButton onClick={(event) => handlePopoverClick(event, 'alignment')}>
                  <AlignHorizontalCenter sx={{ fontSize: 20 }} />
                </StyledListButton>
              </Tooltip>
              <Tooltip arrow title="Text">
                <StyledListButton onClick={(event) => handlePopoverClick(event, 'text')}>
                  <Title sx={{ fontSize: 20 }} />
                </StyledListButton>
              </Tooltip>
              <Tooltip arrow title="Free Draw">
                <StyledListButton active={activeButton === "free draw"} onClick={() => { onFreeDrawing(); setActiveButton("free draw"); }}>
                  <CreateOutlined sx={{ fontSize: 20 }} />
                </StyledListButton>
              </Tooltip>
              <Tooltip arrow title="Select">
                <StyledListButton active={activeButton === "select"} onClick={() => { mainCanvas.selectable = true; setActiveButton("select"); mainCanvas.isDrawingMode = false }}>
                  <Mouse sx={{ fontSize: 20 }} />
                </StyledListButton>
              </Tooltip>
              <Tooltip arrow title="Node Select">
                <StyledListButton active={activeButton === "nodeSelect" && pathSelect} onClick={() => { mainCanvas.selectable = true; setActiveButton("nodeSelect"); handleNodeSelect() }}>
                  <FaCircleNodes sx={{ fontSize: 20 }} />
                </StyledListButton>
              </Tooltip>
              <Tooltip arrow title="Add Node(s)">
                <StyledListButton onClick={() => { addNodeAtMidpoint() }}>
                  <LuPlusCircle style={{ fontSize: 20 }} />
                </StyledListButton>
              </Tooltip>
              <Tooltip arrow title="Remove Node">
                <StyledListButton onClick={() => { removeNodes() }}>
                  <LuMinusCircle style={{ fontSize: 20 }} />
                </StyledListButton>
              </Tooltip>
              <Tooltip arrow title="Connect Handles">
              <Checkbox
                checked={handlesConnected}
                onChange={() => setHandlesConnected(!handlesConnected)}
                color="primary"
                inputProps={{ 'aria-label': 'Connect Handles Checkbox' }}
              />
              </Tooltip>
              <Tooltip arrow title="Chalk And Sponge Filter">
              <StyledListButton active={activeButton === "chalkAndSponge" && pathSelect} onClick={() => { mainCanvas.selectable = true; setActiveButton("chalkAndSponge"); chalkAndSponge(activeObj[0],mainCanvas, zoom,'/filters/chalk.svg', activeNodes,setActiveNodes) }}>

              <BiChalkboard style={{ fontSize: 20 }}/>
                </StyledListButton>
              </Tooltip>
              <Tooltip arrow title="Ripple Filter">
              <StyledListButton active={activeButton === "ripple" && pathSelect} onClick={() => { mainCanvas.selectable = true; setActiveButton("ripple"); chalkAndSponge(activeObj[0],mainCanvas, zoom,'/filters/ripple.svg', activeNodes,setActiveNodes) }}>

              <TbRipple style={{ fontSize: 20 }}/>
                </StyledListButton>
              </Tooltip>
              <Tooltip arrow title="Circularize Node">
                <StyledListButton active={activeButton === "nodeCircular" && pathSelect} onClick={() => { mainCanvas.selectable = true; setActiveButton("nodeCircular"); circularizeNode() }}>
                  <AiOutlineNodeIndex style={{ fontSize: 20 }} />
                </StyledListButton>
              </Tooltip>
              <Tooltip arrow title="Linearize Node">
                <StyledListButton active={activeButton === "nodeLinearize" && pathSelect} onClick={() => { mainCanvas.selectable = true; setActiveButton("nodeLinearize"); linearizeNode() }}>
                  <MdLinearScale style={{ fontSize: 20 }} />
                </StyledListButton>
              </Tooltip>
              <Tooltip arrow title="Bend Path">
                <StyledListButton active={activeButton === "bendPath" && pathSelect} onClick={() => { mainCanvas.selectable = true; setActiveButton("bendPath"); handleBendAlongPath() }}>
                  <TbSTurnDown style={{ fontSize: 20 }} />
                </StyledListButton>
              </Tooltip>
              <Tooltip arrow title="Object To Path">
                <StyledListButton
                  active={activeButton === "ObjectToPath"}
                  onClick={() => {
                    mainCanvas.selectable = true;
                    setActiveButton("ObjectToPath");
                    convertObjectToPath();
                  }}
                >
                  <FaRegObjectUngroup sx={{ fontSize: 20 }} />
                </StyledListButton>
              </Tooltip>
            </Box>
            {/* <Box sx={{display:'flex', justifyContent:'center'}}>
                <Tooltip title='opacity'>
                  <div style={{display:'flex', alignItems:'center'}}>
                    <Typography fontSize={14}>O:</Typography>
                    <TextField 
                      sx={{backgroundColor:'#D3D3D3',borderRadius:5,maxWidth:'50px'}} 
                      inputProps={{
                        style:{
                          color:'white',                           
                          padding:0,borderRadius:5,fontSize:14,textAlign:'center'
                        }
                      }} 
                      disabled value={`${opacity*100}%`}
                      
                    />
                  </div>
                </Tooltip>
              </Box> */}
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Tooltip arrow title="Clipboard">
                <StyledListButton
                  onClick={(event) => handlePopoverClick(event, "clipboard")}
                >
                  <ContentPasteSearchOutlined sx={{ fontSize: 20 }} />
                </StyledListButton>
              </Tooltip>
              <Tooltip arrow title="Undo">
                <StyledListButton sx={{ borderRight: "1px solid #54444429" }}>
                  <UndoOutlined sx={{ fontSize: 20 }} />
                </StyledListButton>
              </Tooltip>
              <Tooltip arrow title="Redo">
                <StyledListButton sx={{ borderRight: "1px solid #54444429" }}>
                  <RedoOutlined sx={{ fontSize: 20 }} />
                </StyledListButton>
              </Tooltip>
              <Tooltip arrow title="Clear">
                <StyledListButton onClick={onEraser}>
                  <Clear sx={{ fontSize: 20 }} />
                </StyledListButton>
              </Tooltip>
            </Box>
          </Toolbar>
        </AppBar>

        <StyledDrawer ref={sidebarRef} variant="permanent" open={open}>
          <DrawerHeader sx={{ mb: 3.5 }}>
            <IconButton onClick={handleDrawerClose}>
              <MenuOpen />
            </IconButton>
          </DrawerHeader>
          <List>
            <label style={{ display: "flex" }} htmlFor="color-picker">
              <input
                type="color"
                id="color-picker"
                style={{ display: "none" }}
                onChange={dispatch(setBackgroundColor)}
              />
            </label>
            <label style={{ display: "flex" }} htmlFor="upload-svg-input">
              <input
                id="upload-svg-input"
                type="file"
                accept=".svg"
                style={{ display: "none" }}
                onChange={onUploadSVG}
              />
            </label>
            {mainMenus?.map((menu, index) => (
              <div key={index}>
                <StyledListButton
                  sx={{ mb: 1.5, flexDirection: "column", rowGap: 1 }}
                  active={activeButton === menu.name}
                  onClick={(event) => handlePopoverClick(event, menu.name)}
                >
                  {menu.icon}
                  <Typography color={"inherit"} fontSize={10}>
                    {menu.label}
                  </Typography>
                </StyledListButton>
              </div>
            ))}
          </List>
        </StyledDrawer>
      </Paper>
    </div>
  );
};

export default Sidebar;
