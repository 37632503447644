import React, { useEffect,useState,useRef } from 'react';
import {
  TabContext,
  TabPanel
} from '@mui/lab';
import {
  Box,
  Typography,
  IconButton,
  Tabs,
  Tab,
  TextField,
  MenuItem,
  Button,
  Tooltip,
  Slider,
  InputAdornment,
} from '@mui/material';
import { Add, Clear, Remove, Upload } from '@mui/icons-material';
import { PopoverListButtons, StyledShapeMenuContainer, StyledSubMenu, StyledSubMenuContainer, StyledSubMenuTitleContainer } from '../../../Utils/styledComps';
import { FaChartSimple, FaShapes } from 'react-icons/fa6';
import PieChartForm from '../ChartForms/PieChart';
import LineChartForm from '../ChartForms/LineChart';
import ScatterChartForm from '../ChartForms/ScatterChart';
import { VennDiagramChart } from 'chartjs-chart-venn';
import VennForm from '../ChartForms/VennChart';
import BoxPlotForm from '../ChartForms/WhiskerChart';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
const CustomShapesPopoverContent = ({ 
  shapes, 
  handlePopoverClose,
charts,
selectedFeature,
onAddChart,
setSelectedFeature,
currentPopover }) => {
  const [selectedChart, setSelectedChart] = useState('');
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState('');

  const handleUploadClick = (e) => {
    e.stopPropagation();

    fileInputRef.current.click();
  };

  const handleClearClick = () => {
    setFileName('');
  };


  useEffect(()=>{
    if(selectedFeature !== 'Shapes' && selectedFeature !== 'Charts'){
      setSelectedFeature('Shapes');
    }
  },[])
  const [entries, setEntries] = useState([
    { label: '', data: '', color: '' },
  ]);
  const extractEntries = (fileData) => {
    return fileData.map((row) => ({
      label: row['Label'] || '',    
      data: row['Data'] || '',     
      color: row['Color'] || '#000000', 
    }));
  };

const handleFileUpload = (e) => {
  const file = e.target.files[0];

  if (file) {
    console.log('handleFileUpload: ', file);
    const reader = new FileReader();
    const fileExtension = file.name.split('.').pop().toLowerCase();

    if (fileExtension === 'csv') {
      reader.onload = (f) => {
        const data = f.target.result;
        const parsedData = Papa.parse(data, { header: true }).data;
        const extractedEntries = extractEntries(parsedData);
        setEntries(extractedEntries);
      };
      reader.readAsText(file);
    } else if (fileExtension === 'xlsx') {
      reader.onload = (f) => {
        const data = new Uint8Array(f.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(worksheet);

        const extractedEntries = extractEntries(parsedData);
        setEntries(extractedEntries);
      };
      reader.readAsArrayBuffer(file);
    } else {
      alert('Unsupported file type. Please upload a CSV or XLSX file.');
    }

    setFileName(file.name);
  }
};


  const handleChange = (index, field, value) => {
    const newEntries = [...entries];
    newEntries[index][field] = value;
    setEntries(newEntries);
  };

  const handleAddEntry = () => {
    setEntries([...entries, { label: '', data: '', color: '' }]);
  };

  const handleRemoveEntry = (index) => {
    const newEntries = entries.filter((_, i) => i !== index);
    setEntries(newEntries);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    try {
      const labels = entries.map(entry => {
        if (!entry.label) throw new Error('Label is missing for one or more entries.');
        return entry.label;
      });
  
      const data = entries.map(entry => {
        const value = parseFloat(entry.data);
        if (isNaN(value)) throw new Error('Data value is invalid for one or more entries.');
        return value || 0;
      });
  
      const backgroundColor = entries.map(entry => entry.color || '#000000');
  
      onAddChart('barVertical', {
        labels,
        data,
        backgroundColor,
      });
  
      console.log('Chart submitted successfully:', { labels, data, backgroundColor });
  
    } catch (error) {
      alert(`Submission error: ${error.message}`);
    }
  };
  

  return (
    <TabContext value={selectedFeature}>
      <Box sx={{ overflow: "hidden",}}>
        <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center", marginRight: "10px", paddingLeft: "10px" }}>
          <Typography sx={{ m: 0, pl: 2, fontSize: 18, color: '#f0f2f7', fontWeight: 800 }}>
            {currentPopover ? currentPopover.charAt(0).toUpperCase() + currentPopover.slice(1) : null}
          </Typography>
          <IconButton edge="end" sx={{ borderRadius: 0 }} onClick={handlePopoverClose}>
            <Clear />
          </IconButton>
        </div>
        <Tabs
          value={selectedFeature}
          onChange={(event, newValue) => setSelectedFeature(newValue)}
          variant="fullWidth"
          sx={{ borderBottom: 1, p: 0, borderColor: 'divider' }}
        >
            <Tooltip arrow title={'Shapes'}>
              <Tab
                sx={{ p: 0, "&:hover, &:active, &:focus": { color: "#00F3FF", borderBottom: "1px solid", borderColor: "#00F3FF" } }}
                icon={<FaShapes/>}
                value={'Shapes'}
              />
            </Tooltip>
            <Tooltip arrow title={'Charts'}>
              <Tab
                sx={{ p: 0, "&:hover, &:active, &:focus": { color: "#00F3FF", borderBottom: "1px solid", borderColor: "#00F3FF" } }}
                icon={<FaChartSimple/>}
                value={'Charts'}
              />
            </Tooltip>
        </Tabs>
          <Box sx={{ overflow: "auto",height: "430px", }}>
          <TabPanel value={'Shapes'} sx={{ overflow: "auto",  width: '300px',padding:0}}>
          <StyledSubMenuContainer >
            <StyledSubMenuTitleContainer >  
              <StyledSubMenu >Basics</StyledSubMenu>
            </StyledSubMenuTitleContainer>
            <StyledShapeMenuContainer>
              {shapes.filter((item) => item.class === "basics").map((shape, index) => (
                <Tooltip key={index} title={shape.label}>
                  <PopoverListButtons onClick={shape.onClick}>
                    {shape.icon}
                  </PopoverListButtons>
                </Tooltip>
              ))}
            </StyledShapeMenuContainer>
            <StyledSubMenuTitleContainer> 
              <StyledSubMenu >Lines </StyledSubMenu>
            </StyledSubMenuTitleContainer>
            <StyledShapeMenuContainer>
              {shapes.filter((item) => item.class === "lines").map((shape, index) => (
                <Tooltip key={index} title={shape.label}>
                  <PopoverListButtons onClick={shape.onClick}>
                    {shape.icon}
                  </PopoverListButtons>
                </Tooltip>
              ))}
            </StyledShapeMenuContainer>
            <StyledSubMenuTitleContainer>
              <StyledSubMenu>Arrows</StyledSubMenu>
            </StyledSubMenuTitleContainer>
            <StyledShapeMenuContainer>
              {shapes.filter((item) => item.class === "arrows").map((shape, index) => (
                <Tooltip key={index} title={shape.label}>
                  <PopoverListButtons onClick={shape.onClick}>
                    {shape.icon}
                  </PopoverListButtons>
                </Tooltip>
              ))}
            </StyledShapeMenuContainer>
            <StyledSubMenuTitleContainer>
              <StyledSubMenu>Misc.</StyledSubMenu>
            </StyledSubMenuTitleContainer>
            <StyledShapeMenuContainer>
              {shapes.filter((item) => item.class === "specials").map((shape, index) => (
                <Tooltip key={index} title={shape.label}>
                  <PopoverListButtons onClick={shape.onClick}>
                    {shape.icon}
                  </PopoverListButtons>
                </Tooltip>
              ))}
            </StyledShapeMenuContainer>
            <StyledSubMenuTitleContainer>
              <StyledSubMenu>Equations & Flow Chart Shapes</StyledSubMenu>
            </StyledSubMenuTitleContainer>
            <StyledShapeMenuContainer>
              {shapes.filter((item) => item.class === "flowChartShapes" || item.class === "equations").map((shape, index) => (
                <Tooltip key={index} title={shape.label}>
                  <PopoverListButtons onClick={shape.onClick}>
                    {shape.icon}
                  </PopoverListButtons>
                </Tooltip>
              ))}
            </StyledShapeMenuContainer>
            <StyledSubMenuTitleContainer>
              <StyledSubMenu>Banners</StyledSubMenu>
            </StyledSubMenuTitleContainer>
            <StyledShapeMenuContainer>
              {shapes.filter((item) => item.class === "banners").map((shape, index) => (
                <Tooltip key={index} title={shape.label}>
                  <PopoverListButtons onClick={shape.onClick}>
                    {shape.icon}
                  </PopoverListButtons>
                </Tooltip>
              ))}
            </StyledShapeMenuContainer>
            
            <StyledSubMenuTitleContainer>
              <StyledSubMenu>Speech Bubbles</StyledSubMenu>
            </StyledSubMenuTitleContainer>
            <StyledShapeMenuContainer>
              {shapes.filter((item) => item.class === "speech").map((shape, index) => (
                <Tooltip key={index} title={shape.label}>
                  <PopoverListButtons onClick={shape.onClick}>
                    {shape.icon}
                  </PopoverListButtons>
                </Tooltip>
              ))}
            </StyledShapeMenuContainer>
            <StyledSubMenuTitleContainer>
              <StyledSubMenu>Library</StyledSubMenu>
            </StyledSubMenuTitleContainer>
            <StyledShapeMenuContainer>
              {shapes.filter((item) => item.class === "images").map((shape, index) => (
                <Tooltip key={index} title={shape.label}>
                  <PopoverListButtons onClick={shape.onClick}>
                    {shape.icon}
                  </PopoverListButtons>
                </Tooltip>
              ))}
            </StyledShapeMenuContainer>
          </StyledSubMenuContainer>
          </TabPanel>
          <TabPanel value={'Charts'} sx={{ overflow: "auto", width: '300px',padding:0}}>
          <StyledSubMenuContainer sx={{height:'100%'}}>
          <StyledShapeMenuContainer sx={{gap:5}}>
            <StyledSubMenuTitleContainer>
              <StyledSubMenu>Charts</StyledSubMenu>
            </StyledSubMenuTitleContainer>
              {charts.map((item,index) => (
                <Tooltip key={index} title={item.label}>
                <PopoverListButtons sx={{border:'1px solid white'}} onClick={()=>setSelectedChart(item.label)}>
                  {item.icon}
                </PopoverListButtons>
              </Tooltip>
              ))}
              {selectedChart !== '' && 
              <StyledSubMenuTitleContainer > 

              <StyledSubMenu >{selectedChart}</StyledSubMenu>

              
            </StyledSubMenuTitleContainer>}
              {selectedChart !== '' && 
          selectedChart === 'Histograms' 
            ?
            <Box sx={{px:1.5, pb:2}}>
              
              <Box component="form" onSubmit={handleSubmit}>
                <Box >
                  {entries.map((entry, index) => (
                    <Box key={index} sx={{my:2}}>
                      <Box display={'flex'} justifyContent={'space-between'}>
                      <Typography fontSize={16}>Data Set: {index+1}</Typography> 
                      {entries.length > 1 && (
                          <IconButton onClick={() => handleRemoveEntry(index)}>
                            <Remove />
                          </IconButton>
                        )}
                      </Box>
                      <Box display="flex" flexDirection={'column'} gap={3} alignItems="center">
                        <TextField
                          label="Label"
                          value={entry.label}
                          onChange={(e) => handleChange(index, 'label', e.target.value)}
                          sx={{ minWidth: '250px' }}                        />
                        <TextField
                          label="Data"
                          type="number"
                          value={entry.data}
                          onChange={(e) => handleChange(index, 'data', e.target.value)}
                          sx={{ minWidth: '250px' }}                        />
                        <TextField
                          label="Background Color"
                          value={entry.color}
                          type='color'
                          onChange={(e) => handleChange(index, 'color', e.target.value)}
                          style={{ marginRight: 8 }}
                        />
                        <input ref={fileInputRef} type="file" accept=".xlsx, .xls, .csv" style={{ display: 'none' }} onChange={handleFileUpload} />
                        <TextField

                          value={fileName}
                          variant="outlined"
                          onClick={handleUploadClick}
                          placeholder="Upload a file"
                          inputProps={{
                            style: {
                              cursor: fileName ? 'default' : 'pointer',
                              color: '#ffffff'
                            }
                          }}
                          InputProps={{
                            readOnly: true,
                            endAdornment: (
                              <InputAdornment sx={{ p: 0 }} position="end">
                                <IconButton sx={{ borderRadius: 0, "&:hover": { color: 'blue' } }} onClick={handleUploadClick}>
                                  <Upload />
                                </IconButton>
                                {fileName && (
                                  <IconButton sx={{ borderRadius: 0, "&:hover": { color: 'red' } }} onClick={handleClearClick}>
                                    <Clear />
                                  </IconButton>
                                )}
                              </InputAdornment>
                            ),
                          }}
                          />
                      </Box>
                    </Box>
                  ))}
                  <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Button type="button" variant="contained" color="secondary" onClick={handleAddEntry}>
                      <Add /> 
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                      Add Bar Chart
                    </Button>
                    </Box>
                </Box>
              </Box>
            </Box>
            : selectedChart === 'Pie Chart'  ?
            <Box>
              <PieChartForm onAddChart={onAddChart} />

            </Box>
            : selectedChart === 'Line Chart' ? 
            <Box>
              <LineChartForm onAddChart={onAddChart}/>
            </Box>
            : selectedChart === 'Scatter Plot' ? 
            <Box>
              <ScatterChartForm onAddChart={onAddChart}/>
            </Box>
            : selectedChart === 'Venn Diagram' ? 
            <Box>
              <VennForm onSubmit={onAddChart}/>
            </Box>
            : selectedChart === 'Whisker Chart' ? 
            <Box>
              <BoxPlotForm onSubmit={onAddChart}/>
            </Box>
            : null
          }
            </StyledShapeMenuContainer>
            </StyledSubMenuContainer>
          </TabPanel>
          
      </Box>
      </Box>
      
    </TabContext>
    
  );
};

export default CustomShapesPopoverContent;
