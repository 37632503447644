import React, { useState } from "react";
import { Table, TableBody, TableContainer, TableRow, TableCell, Paper, Collapse } from "@mui/material";
import FeatureTableHeader from "./FeatureTableHeader";
import FeatureTableRow from "./FeatureTableRow";

const featureData = [
  {
    feature: "Stock photos, graphics, fonts, videos, and audio",
    free: "3.2+ million",
    pro: "127+ million",
    teams: "127+ million",
    enterprise: "127+ million",
  },
  {
    feature: "Templates",
    free: "2.1+ million",
    pro: "3.8+ million",
    teams: "3.8+ million",
    enterprise: "3.8+ million",
  },
  {
    feature: "Cloud storage",
    free: false,
    pro: true,
    teams: true,
    enterprise: true,
  },
  {
    feature: "Export designs as PDF, JPG, PNG",
    free: true,
    pro: true,
    teams: true,
    enterprise: true,
  },
  {
    feature: "ISO 27001 certification and compliance",
    free: true,
    pro: true,
    teams: true,
    enterprise: true,
  },
  {
    feature: "Brand Template",
    free: false,
    pro: true,
    teams: true,
    enterprise: true,
  },
  {
    feature: "Social sharing",
    free: true,
    pro: true,
    teams: true,
    enterprise: true,
  },
  {
    feature: "Customisable templates",
    free: false,
    pro: true,
    teams: true,
    enterprise: true,
  },
];

const FeatureTable = () => {
  const [isOpen, setIsOpen] = useState(true);

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="feature comparison table">
        <FeatureTableHeader onToggle={handleToggle} isOpen={isOpen} />
        <TableBody>
          <TableRow>
            <TableCell colSpan={5}>
              <Collapse in={isOpen}>
                <Table>
                  <TableBody>
                    {featureData.map((feature, index) => (
                      <FeatureTableRow key={index} feature={feature} />
                    ))}
                  </TableBody>
                </Table>
              </Collapse>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FeatureTable;
