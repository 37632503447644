import React, { useState, useEffect, useRef } from 'react';
import {
  TabContext,
  TabPanel
} from '@mui/lab';
import ColorPicker from 'react-best-gradient-color-picker'
import {
  Box,
  Typography,
  IconButton,
  Tabs,
  Tab,
  TextField,
  MenuItem,
  Button,
  Tooltip,
  Slider,
  InputAdornment
} from '@mui/material';
import { Clear, Add, Upload, GridGoldenratio, Grid4x4Sharp, Close } from '@mui/icons-material';
import ReactGPicker from 'react-gcolor-picker';
import { PopoverListButtons, StyledShapeMenuContainer, StyledSubMenu, StyledSubMenuContainer, StyledSubMenuDivisionBox, StyledSubMenuTitleContainer } from '../../../Utils/styledComps';
import { fabric } from 'fabric';

const CustomCanvasPopoverContent = ({
  selectedFeature,
  setSelectedFeature,
  currentPopover,
  handlePopoverClose,
  canvas,
  mainCanvas,
  tempSize,
  changeOpacity,
  setTempSize,
  handleUnitChange,
  onSizeChange,
  size,
  onPredifinedSizeChange,
  opacity,
  canvasColor,
  changeBackground,
  onAddCanvas,
  canvasImage,
  switchCanvas
}) => {
  const lanes = [{ label: "1", val: 1 }, { label: "2", val: 2 }, { label: "3", val: 3 }];
  const [selectedLane, setSelectedLane] = useState(1);
  const [selectedSize, setSelectedSize] = useState(null);
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState('');
  const [grid,setGrid] = useState('');
  const [gridColor,setGridColor] = useState('#ff0000');
  const [spacing, setSpacing] = useState(50); 
  const [startX, setStartX] = useState(0); 
  const [startY, setStartY] = useState(0); 
  const [angle1, setAngle1] = useState(30); 
  const [angle2, setAngle2] = useState(150);
  
  const clearGrid = () => {
    mainCanvas.getObjects('gridLine').forEach((obj) => {
      mainCanvas.remove(obj);
    });
    setGrid('');
    mainCanvas.renderAll();
  };
  const handleCanvasResize = () => {
    if (mainCanvas) {
      if (grid === 'Rect') {
        addRectangularGrid(startX, startY, spacing);
      } else if (grid === 'Axo') {
        addAxonometricGrid(startX, startY, spacing, angle1, angle2);
      }
    }
  };

  useEffect(() => {
    if (mainCanvas) {
      
      handleCanvasResize();
    }
  }, [mainCanvas,size]);

  const addRectangularGrid = (x,y,spacing) => {
    clearGrid();
    const gridSpacing = spacing; 
    const width = mainCanvas.getWidth()/mainCanvas.getZoom()*2;
    const height = mainCanvas.getHeight()/mainCanvas.getZoom()*2;
    
    console.log('addRectangularGrid: ', width,height);
    for (let i = x; i <= width; i += gridSpacing) {
      const line = new fabric.Line([i, 0, i, height], {
        stroke: gridColor,
        selectable: false,
        evented: false,
        name: 'gridLine',
      });
      line.set({ type: 'gridLine' });
      mainCanvas.add(line);
    }

    for (let i = y; i <= height; i += gridSpacing) {
      const line = new fabric.Line([0, i, width, i], {
        stroke: gridColor,
        selectable: false,
        evented: false,
        name: 'gridLine',
      });
      line.set({ type: 'gridLine' });
      mainCanvas.add(line);
    }

    mainCanvas.renderAll();
  };

  const addAxonometricGrid = (x, y, spacing,a1,a2) => {
    console.log('addAxonometricGrid: ', x, y,spacing);
    clearGrid();
    const gridSpacing = spacing; 
    const width = mainCanvas.getWidth() / mainCanvas.getZoom() *2;
    const height = mainCanvas.getHeight() / mainCanvas.getZoom()*2;
  
    for (let i = -width; i <= width; i += gridSpacing) {
      const line = new fabric.Line([i + x, height + y, width + i + x, y], {
        stroke: gridColor,
        selectable: false,
        evented: false,
        name: 'gridLine',
      });
      line.set({ type: 'gridLine' });
      mainCanvas.add(line);
    }
  
    for (let i = -width; i <= width; i += gridSpacing) {
      const line = new fabric.Line([i + x, y, i + width + x, height + y], {
        stroke: gridColor,
        selectable: false,
        evented: false,
        name: 'gridLine',
      });
      line.set({ type: 'gridLine' });
      mainCanvas.add(line);
    }
  
    // Add vertical lines, starting from x and y
    for (let i = 0; i <= width; i += gridSpacing) {
      const line = new fabric.Line([i + x, y, i + x, height + y], {
        stroke: gridColor,
        selectable: false,
        evented: false,
        name: 'gridLine',
      });
      line.set({ type: 'gridLine' });
      mainCanvas.add(line);
    }
  
    mainCanvas.renderAll();
  };
  


  
  
  

  const handleGridClick = (gridType) => {
    setGrid(gridType);
  };
  const handleSubmit = (gridType, x,y,spacing,a1,a2) =>{
    if (gridType === 'Rect') {
      addRectangularGrid( x,y,spacing);
    } else if (gridType === 'Axo') {
      addAxonometricGrid(x,y,spacing,a1,a2);
    }
  }

  //Graphical Abstract All three type 
  const circularPreviewRef = useRef(null);
  const leftToRightPreviewRef = useRef(null);
  const topToBottomPreviewRef = useRef(null);
  useEffect(() => {
    // Initialize preview canvases
    initPreviewCanvas(circularPreviewRef.current, addCircularTemplate);
    initPreviewCanvas(leftToRightPreviewRef.current, addLeftToRightTemplate);
    initPreviewCanvas(topToBottomPreviewRef.current, addTopToBottomTemplate);
  },);

  const initPreviewCanvas = (canvasElement, drawFunction) => {
    const previewCanvas = new fabric.StaticCanvas(canvasElement, {
      width: 250,
      height: 200,
      backgroundColor: '#ffffff',
    });
    drawFunction(previewCanvas);
  };
  const addCircularTemplate = (canvasInstance) => {
    const canvasWidth = canvasInstance.width;
    const canvasHeight = canvasInstance.height;

    // Main Title Section
    const titleHeight = canvasHeight * 0.1;
    const titleRect = new fabric.Rect({
        width: canvasWidth * 0.9,
        height: titleHeight,
        fill: '#2c3e50', // Dark blue background
        left: canvasWidth * 0.05,
        top: canvasHeight * 0.05,
        stroke: '#333',
        strokeWidth: 2,
    });

    const titleTextbox = new fabric.Textbox('Graphical Abstract Title or One Sentence Description', {
        width: canvasWidth * 0.88,
        fontSize: titleHeight * 0.4,
        fontWeight: 'bold',
        left: canvasWidth * 0.06,
        top: canvasHeight * 0.06,
        fill: '#ffffff', // White text color
        editable: true,
        textAlign: 'center',
    });

    // Center Circle representing the research topic
    const circleRadius = Math.min(canvasWidth, canvasHeight) * 0.15;
    const centerCircle = new fabric.Circle({
        radius: circleRadius,
        fill: '#D3D3D3', // Light gray fill
        stroke: '#333',
        strokeWidth: 2,
        left: canvasWidth / 2 - circleRadius,
        top: canvasHeight / 2 - circleRadius,
    });

    const centerText = new fabric.Textbox('Research topic', {
        width: circleRadius * 2,
        fontSize: circleRadius * 0.3,
        fontWeight: 'bold',
        left: canvasWidth / 2 - circleRadius,
        top: canvasHeight / 2 - circleRadius * 0.2,
        textAlign: 'center',
        fill: '#000000',
    });

    // Lines from the edge of the circle to the four corners
    const lineLength = Math.min(canvasWidth, canvasHeight) * 0.15;
    const angleOffset = Math.PI / 4;

    const line1 = new fabric.Line([
        canvasWidth / 2 - circleRadius * Math.cos(angleOffset), 
        canvasHeight / 2 - circleRadius * Math.sin(angleOffset), 
        canvasWidth / 2 - circleRadius * Math.cos(angleOffset) - lineLength, 
        canvasHeight / 2 - circleRadius * Math.sin(angleOffset) - lineLength
    ], {
        stroke: '#333',
        strokeWidth: 2,
    });

    const line2 = new fabric.Line([
        canvasWidth / 2 + circleRadius * Math.cos(angleOffset), 
        canvasHeight / 2 - circleRadius * Math.sin(angleOffset), 
        canvasWidth / 2 + circleRadius * Math.cos(angleOffset) + lineLength, 
        canvasHeight / 2 - circleRadius * Math.sin(angleOffset) - lineLength
    ], {
        stroke: '#333',
        strokeWidth: 2,
    });

    const line3 = new fabric.Line([
        canvasWidth / 2 - circleRadius * Math.cos(angleOffset), 
        canvasHeight / 2 + circleRadius * Math.sin(angleOffset), 
        canvasWidth / 2 - circleRadius * Math.cos(angleOffset) - lineLength, 
        canvasHeight / 2 + circleRadius * Math.sin(angleOffset) + lineLength
    ], {
        stroke: '#333',
        strokeWidth: 2,
    });

    const line4 = new fabric.Line([
        canvasWidth / 2 + circleRadius * Math.cos(angleOffset), 
        canvasHeight / 2 + circleRadius * Math.sin(angleOffset), 
        canvasWidth / 2 + circleRadius * Math.cos(angleOffset) + lineLength, 
        canvasHeight / 2 + circleRadius * Math.sin(angleOffset) + lineLength
    ], {
        stroke: '#333',
        strokeWidth: 2,
    });

    // Textboxes for "Text, graph, or illustration" positioned around the circle
    const textWidth = canvasWidth * 0.2;
    const textHeight = circleRadius * 0.3;
    const text1 = new fabric.Textbox('Text, graph, or illustration', {
        width: textWidth,
        fontSize: textHeight,
        left: line1.x2 - textWidth / 1.1,
        top: line1.y2 - textHeight / 2,
        textAlign: 'center',
        fill: '#000000',
    });

    const text2 = new fabric.Textbox('Text, graph, or illustration', {
        width: textWidth,
        fontSize: textHeight,
        left: line2.x2 - textWidth / 8,
        top: line2.y2 - textHeight / 1,
        textAlign: 'center',
        fill: '#000000',
    });

    const text3 = new fabric.Textbox('Text, graph, or illustration', {
        width: textWidth,
        fontSize: textHeight,
        left: line3.x2 - textWidth / 1.1,
        top: line3.y2 - textHeight / 2,
        textAlign: 'center',
        fill: '#000000',
    });

    const text4 = new fabric.Textbox('Text, graph, or illustration', {
        width: textWidth,
        fontSize: textHeight,
        left: line4.x2 - textWidth / 8,
        top: line4.y2 - textHeight / 2,
        textAlign: 'center',
        fill: '#000000',
    });

    // Add all elements to the canvas
    canvasInstance.add(
        titleRect, titleTextbox, 
        centerCircle, centerText, 
        line1, line2, line3, line4, 
        text1, text2, text3, text4
    );
    canvasInstance.renderAll();
};

const addTopToBottomTemplate = (canvasInstance) => {
  // Main Title Section
  const titleRect = new fabric.Rect({
    width: canvasInstance.width - 20,
    height: 40,
    fill: "#e3f2fd",
    left: 10,
    top: 10,
    stroke: "#333",
    strokeWidth: 2,
  });

  const titleTextbox = new fabric.Textbox("Main Title", {
    width: canvasInstance.width - 40,
    fontSize: 14,
    fontWeight: "bold",
    left: 20,
    top: 20,
    fill: "#000000",
    editable: true,
    textAlign: "center", // Center the text horizontally
  });

  // Subtitle Section

  const subtitleTextbox = new fabric.Textbox("Subtitle", {
    width: canvasInstance.width - 20,
    fontSize: 14,
    left: 20,
    top: 57,
    fill: "#000000",
    editable: true,
    textAlign: "center", // Center the subtitle horizontally
  });
  const lineTop = 75; // Adjust position as needed

  const line = new fabric.Line(
    [20, lineTop, canvasInstance.width - 10, lineTop],
    {
      stroke: "#000000",
      strokeWidth: 2,
      selectable: false, // Prevents the line from being selected
    }
  );

  // Add the line to the canvas
  canvasInstance.add(line);

  // Input Text Boxes
  const inputBoxWidth = (canvasInstance.width - 40) / 2; // Two boxes per row, with some padding
  const inputBoxHeight = 30;

  const inputBox1 = new fabric.Textbox("Text 1", {
    width: inputBoxWidth,
    height: inputBoxHeight,
    fontSize: 12,
    left: 20,
    top: 85,
    fill: "#000000",
    editable: true,
  });

  const inputBox2 = new fabric.Textbox("Text 2", {
    width: inputBoxWidth,
    height: inputBoxHeight,
    fontSize: 12,
    left: 20 + inputBoxWidth + 20, // Position next to inputBox1
    top: 85,
    fill: "#000000",
    editable: true,
  });

  const inputBox3 = new fabric.Textbox("Text 3", {
    width: inputBoxWidth,
    height: inputBoxHeight,
    fontSize: 12,
    left: 20,
    top:100, // Position below the first row
    fill: "#000000",
    editable: true,
  });

  const inputBox4 = new fabric.Textbox("Text 4", {
    width: inputBoxWidth,
    height: inputBoxHeight,
    fontSize: 12,
    left: 20 + inputBoxWidth + 20,
    top: 100,
    fill: "#000000",
    editable: true,
  });

  // Image Section

  const imageRect = new fabric.Rect({
    width: canvasInstance.width - 20,
    height: 70,
    fill: "#ffffff",
    left: 10,
    top: 120,
    stroke: "#333",
    strokeWidth: 2,
  });

  const addImageTextbox = new fabric.Textbox("Insert Image or Graphs", {
    width: canvasInstance.width - 40,
    fontSize: 14,
    left: 20,
    top:130,
    fill: "#000000",
    editable: true,
    fontStyle: "italic",
  });

  // Add all elements to the canvas
  canvasInstance.add(
    titleRect,
    titleTextbox,
    subtitleTextbox,
    inputBox1,
    inputBox2,
    inputBox3,
    inputBox4,
    imageRect,
    addImageTextbox
  );
  canvasInstance.renderAll();
};
const addLeftToRightTemplate = (canvasInstance) => {
    // Calculate the width for subtitle and image sections based on the canvas width
    const subtitleWidth = canvasInstance.width * 0.4;
    const imageWidth = canvasInstance.width * 0.6;

    // Title Section
    const titleRect = new fabric.Rect({
        width: canvasInstance.width - 20,
        height: 50,
        fill: '#e3f2fd',
        left: 10,
        top: 10,
        stroke: '#333',
        strokeWidth: 2,
    });

    const titleTextbox = new fabric.Textbox('Main Title', {
        width: canvasInstance.width - 40,  // Adjusted for padding
        fontSize: 18,
        fontWeight: 'bold',
        left: 20,
        top: 20,
        fill: '#000000', // Set text color to black
        editable: true,
    });

    // Left Partition (Subtitle Section)
    const leftRect = new fabric.Rect({
        width: subtitleWidth,
        height: 100,
        fill: '#fff9c4',
        left: 10,
        top: 70,
        stroke: '#333',
        strokeWidth: 2,
    });

    const subtitleTextbox = new fabric.Textbox('Subtitle', {
        width: subtitleWidth - 20,  // Adjusted for padding
        fontSize: 15,
        left: 20,
        top: 75,
        fill: '#000000', // Set text color to black
        editable: true,
    });

    const subTextBox1 = new fabric.Textbox('Text1', {
        width: subtitleWidth - 20,  // Adjusted for padding
        fontSize: 12,
        left: 20,
        top: 95,
        fill: '#000000', // Set text color to black
        editable: true,
    });

    const subTextBox2 = new fabric.Textbox('Text2', {
        width: subtitleWidth - 20,  // Adjusted for padding
        fontSize: 12,
        left: 20,
        top: 110,
        fill: '#000000', // Set text color to black
        editable: true,
    });

    const subTextBox3 = new fabric.Textbox('Text3', {
        width: subtitleWidth - 20,  // Adjusted for padding
        fontSize: 12,
        left: 20,
        top: 125,
        fill: '#000000', // Set text color to black
        editable: true,
    });

    // Right Partition (Image/Illustration Section)
    const rightRect = new fabric.Rect({
        width: imageWidth-20,
        height: 100,
        fill: '#ffffff',
        left: subtitleWidth + 10, // Positioned immediately next to the left section
        top: 70,
        stroke: '#333',
        strokeWidth: 2,
    });

    const addImageTextbox = new fabric.Textbox('Insert Image or Graphs', {
        width: imageWidth - 20,  // Adjusted for padding
        fontSize: 14,
        left: subtitleWidth + 20,  // Adjusted for padding
        top: 100,
        fill: '#000000', // Set text color to black
        editable: true,
        fontStyle: 'italic',
    });

    // Add all elements to the canvas
    canvasInstance.add(
        titleRect, titleTextbox,
        leftRect, subtitleTextbox, subTextBox1, subTextBox2, subTextBox3,
        rightRect, addImageTextbox
    );

    canvasInstance.renderAll();
};

  const handleTemplateSelect = (drawFunction) => {
    // mainCanvas.clear();    //To remove the item from the main canvas this is not required
    drawFunction(mainCanvas);
  };

  const handleUploadClick = (e) => {
    e.stopPropagation();

    fileInputRef.current.click();
  };

  const handleClearClick = () => {
    mainCanvas.setBackgroundImage(null, () => {
      mainCanvas.renderAll();
    });
    setFileName('');
  };
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file && mainCanvas) {
      console.log('handleImageUpload: ', mainCanvas, file)
      const reader = new FileReader();
      reader.onload = (f) => {
        const data = f.target.result;
        const widthC = mainCanvas.width / mainCanvas.getZoom();
        const heightC = mainCanvas.height / mainCanvas.getZoom()

        fabric.Image.fromURL(data, (img) => {
          const widthC = mainCanvas.width / mainCanvas.getZoom();
          const heightC = mainCanvas.height / mainCanvas.getZoom();
          mainCanvas.setBackgroundImage(img, mainCanvas.renderAll.bind(mainCanvas), {
            scaleX: widthC / img.width,
            scaleY: heightC / img.height,
          });
        });
      };
      
      setFileName(file.name);
      reader.readAsDataURL(file);
      
    }
  };
  const rescaleBackgroundImage = () => {
    const backgroundImage = mainCanvas.backgroundImage;
    if (backgroundImage) {
      const widthC = mainCanvas.width / mainCanvas.getZoom();
      const heightC = mainCanvas.height / mainCanvas.getZoom();
      backgroundImage.set({
        scaleX: widthC / backgroundImage.width,
        scaleY: heightC / backgroundImage.height,
      });
      mainCanvas.renderAll(); 
    }
  };
  
  useEffect(() => {
    if (mainCanvas) {
      rescaleBackgroundImage();
  
      const resizeObserver = new ResizeObserver(() => {
        rescaleBackgroundImage();
      });
  
      resizeObserver.observe(mainCanvas.wrapperEl); 
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [mainCanvas]);

  const marks = [
    { value: 0, label: '0%' },
    { value: 0.2, label: '20%' },
    { value: 0.4, label: '40%' },
    { value: 0.6, label: '60%' },
    { value: 0.8, label: '80%' },
    { value: 1, label: '100%' },
  ];

  return (
    <TabContext value={selectedFeature}>
      <Box sx={{ width: '340px', overflow: "hidden" }}>
        <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center", marginRight: "10px", paddingLeft: "10px" }}>
          <Typography sx={{ m: 0, pl: 2, fontSize: 18, color: '#f0f2f7', fontWeight: 800 }}>
            {currentPopover ? currentPopover.charAt(0).toUpperCase() + currentPopover.slice(1) : null}
          </Typography>
          <IconButton edge="end" sx={{ borderRadius: 0 }} onClick={handlePopoverClose}>
            <Clear />
          </IconButton>
        </div>
        
        <Tabs
          value={selectedFeature}
          onChange={(event, newValue) => setSelectedFeature(newValue)}
          variant="scrollable"
          sx={{ borderBottom: 1, p: 0, borderColor: 'divider' }}
        >
          {canvas.map((item) => (
            <Tooltip key={item.label} arrow title={item.label}>
              <Tab
                sx={{ p: 0, "&:hover, &:active, &:focus": { color: "#00F3FF", borderBottom: "1px solid", borderColor: "#00F3FF" } }}
                icon={item.icon}
                value={item.label}
              />
            </Tooltip>
          ))}
        </Tabs>

        {canvas.map((item) => (
          <TabPanel key={item.label} value={item.label} sx={{ overflow: "auto", height: "430px", padding: "10px" }}>
            <StyledSubMenuContainer sx={{minHeight: "410px"}}>
              {item.label === 'Size' && (
                <div>
                  <StyledSubMenuTitleContainer>
                    <StyledSubMenu>Canvas Sizes</StyledSubMenu>
                  </StyledSubMenuTitleContainer>
                  <StyledSubMenu sx={{textDecoration:'none',my:2}}>Custom Size</StyledSubMenu>

                  <Box sx={{ display: "flex", gap: 2, mt: 2, justifyContent: "space-between", alignItems: "center" }}>
                    <TextField
                      value={tempSize.height}
                      onChange={(e) => setTempSize({ ...tempSize, height: e.target.value })}
                      InputLabelProps={{ shrink: true }}
                      label="Height"
                    />
                    X
                    <TextField
                      value={tempSize.width}
                      onChange={(e) => setTempSize({ ...tempSize, width: e.target.value })}
                      InputLabelProps={{ shrink: true }}
                      label="Width"
                    />
                    <TextField
                      onChange={handleUnitChange}
                      InputLabelProps={{ shrink: true }}
                      select
                      label="Unit"
                      sx={{ minWidth: "50px" }}
                    >
                      {item.children?.filter((child) => !child.label.startsWith("A")).map((child, index) => (
                        <MenuItem key={index} value={child.label}>
                          {child.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                  <div style={{ textAlign: "center", marginTop: "10px" }}>
                    <Button onClick={() => onSizeChange(tempSize)} variant='outlined'>
                      Apply
                    </Button>
                  </div>
                  <StyledSubMenu sx={{textDecoration:'none',my:2}}>Prediefined Sizes</StyledSubMenu>
                  <StyledShapeMenuContainer>
                    {item.children?.filter((child) => child.label.startsWith("A")).map((obj, index) => (
                      <Tooltip key={index} title={obj.label}>
                        <Button
                          variant="outlined"
                          onClick={() => onPredifinedSizeChange(obj.label)}
                          sx={{ border: "1px solid", borderColor: "inherit", borderRadius: 0 }}
                        >
                          {obj.label}
                        </Button>
                      </Tooltip>
                    ))}
                  </StyledShapeMenuContainer>
                </div>
              )}

              {item.label === 'Background' && (
                <>
                  <StyledSubMenuTitleContainer>
                    <StyledSubMenu>Background Color</StyledSubMenu>
                  </StyledSubMenuTitleContainer>
                  <ColorPicker value={canvasColor} style={{ borderRadius: '10px', padding: 1 }} hideEyeDrop onChange={changeBackground} />
                  <input ref={fileInputRef} type="file" accept="image/*" style={{ display: 'none' }} onChange={handleImageUpload} />
                  <StyledSubMenuDivisionBox>
                    <Typography sx={{ mb: 1, color: '#312e55' }} align='center'>Image as Background</Typography>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                      <TextField

                        value={fileName}
                        variant="outlined"
                        onClick={handleUploadClick}
                        placeholder="Upload an image"
                        inputProps={{
                          style: {
                            cursor: fileName ? 'default' : 'pointer',
                            color: '#312e55'
                          }
                        }}
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <InputAdornment sx={{ p: 0 }} position="end">
                              <IconButton sx={{ borderRadius: 0, "&:hover": { color: 'blue' } }} onClick={handleUploadClick}>
                                <Upload />
                              </IconButton>
                              {fileName && (
                                <IconButton sx={{ borderRadius: 0, "&:hover": { color: 'red' } }} onClick={handleClearClick}>
                                  <Clear />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />

                    </div>
                  </StyledSubMenuDivisionBox>

                  <StyledSubMenuDivisionBox>
                    <Typography align='center' color='#312e55' fontSize={16}>Background Opacity </Typography>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Slider
                        sx={{
                          maxWidth: '220px',
                          '& .MuiSlider-mark': {
                            backgroundColor: 'blue',
                            height: '8px',
                            width: '2px',
                            '&.MuiSlider-markActive': {
                              opacity: 1,
                              backgroundColor: 'navyblue',
                            },
                          },
                          '& .MuiSlider-markLabel': {
                            color: 'black',
                            color: 'black',
                          },
                        }}
                        value={opacity}
                        onChange={(e, newValue) => changeOpacity(newValue)}
                        step={0.01}
                        min={0}
                        max={1}
                        valueLabelDisplay="auto"
                        marks={marks}
                      />
                    </div>
                  </StyledSubMenuDivisionBox>
                </>

              )}
              {item.label === 'Grids' && (
                <>
                  <StyledSubMenuTitleContainer>
                    <StyledSubMenu>Grids</StyledSubMenu>
                  </StyledSubMenuTitleContainer>
                  <div style={{ display: "flex",flexDirection:"column", gap: 8, margin: "10px"}}>
                    <Button variant='outlined' onClick={() => handleGridClick('Rect')} sx={{alignItems:"center",justifyContent:"space-between", display:"flex"}}>
                      <div style={{display:"flex",alignItems:"center"}}>
                        <Grid4x4Sharp/>
                      </div>
                      <div style={{display:"flex",alignItems:"center"}}>
                        Rectangular Grid
                      </div>
                    </Button>
                    <Button variant='outlined' onClick={() => handleGridClick('Axo')} sx={{alignItems:"center",justifyContent:"space-between", display:"flex"}}>
                      <div style={{display:"flex",alignItems:"center"}}>
                        <GridGoldenratio/>
                      </div>
                      <div style={{display:"flex",alignItems:"center"}}>
                        Axonometric Grid
                      </div>
                    </Button>
                    <Button variant='outlined' onClick={() => clearGrid()} sx={{alignItems:"center",justifyContent:"space-between", display:"flex"}}>
                      <div style={{display:"flex",alignItems:"center"}}>
                        <Close/>
                      </div>
                      <div style={{display:"flex",alignItems:"center"}}>
                        Clear
                      </div>
                    </Button>
                  </div>
                  {grid && (
            <div style={{ display: "flex", flexDirection: "column", gap: 8, marginTop: "10px" }}>
              <Typography>{grid} Grid</Typography>
              <TextField
                label="Grid Spacing"
                type="number"
                value={spacing}
                onChange={(e) => setSpacing(parseInt(e.target.value, 10))}
              />
              <TextField
                label="Start X"
                type="number"
                value={startX}
                onChange={(e) => setStartX(parseInt(e.target.value, 10))}
              />
              <TextField
                label="Start Y"
                type="number"
                value={startY}
                onChange={(e) => setStartY(parseInt(e.target.value, 10))}
              />
              <ColorPicker 
              hideControls hideGradientControls hideInputs hidePresets
              color={gridColor} value={gridColor} onChange={(e)=>setGridColor(e)}/>
              {/* {grid === 'Axo' && (
                    <>
                        <TextField
                            label="Angle 1"
                            type="number"
                            value={angle1}
                            onChange={(e) => setAngle1(parseInt(e.target.value, 10))}
                        />
                        <TextField
                            label="Angle 2"
                            type="number"
                            value={angle2}
                            onChange={(e) => setAngle2(parseInt(e.target.value, 10))}
                        />
                    </>
                )} */}
              <Button variant="contained" onClick={() => handleSubmit(grid,startX,startY, spacing,angle1,angle2)}>
                Submit Grid Config
              </Button>
            </div>
          )}
                  
                </>
              )}

              {item.label === 'Poster' && (
                <>
                 <StyledSubMenuTitleContainer>
                    <StyledSubMenu>Posters</StyledSubMenu>
                  </StyledSubMenuTitleContainer>
                  <div style={{ display: "flex", gap: 15, flexWrap: "wrap" }}>
                    {item.children.map((obj, index) => (
                      <Tooltip key={index} title={obj.label}>
                        <Button
                          variant="outlined"
                          onClick={() => setSelectedSize(obj.label)}
                          sx={{
                            border: '1px solid',
                            borderColor: 'inherit',
                            borderRadius: 0,
                            maxWidth: '60px',
                            backgroundColor: selectedSize === obj.label ? 'lightgray' : 'inherit',
                          }}

                        >
                          {obj.icon}
                        </Button>
                      </Tooltip>
                    ))}
                  </div>
                  <div style={{ display: "flex", alignItems: "center", marginTop: '30px', justifyContent: "center", gap: 10 }}>
                    <Typography fontSize={16}> Poster Lane </Typography>
                    <TextField
                      onChange={(e) => setSelectedLane(e.target.value)}
                      InputLabelProps={{ shrink: false }}
                      select
                      value={selectedLane}
                      // label="Unit"
                      sx={{ minWidth: "50px" }}
                    >
                      {lanes.map((child, index) => (
                        <MenuItem key={index} value={child.val}>
                          {child.label}
                        </MenuItem>
                      ))}
                    </TextField>

                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                    <Button onClick={() => onPredifinedSizeChange(selectedSize, selectedLane)} variant='outlined'>
                      Apply
                    </Button>
                  </div>
                </>
              )}

              {item.label === 'Banner' && (
                <>
                <StyledSubMenuTitleContainer>
                    <StyledSubMenu>Banners</StyledSubMenu>
                  </StyledSubMenuTitleContainer>
                  <div style={{ display: "flex", gap: 15, margin: "10px", flexWrap: "wrap" }}>
                    {item.children.map((obj, index) => (
                      <Tooltip key={index} title={obj.label}>
                        <Button
                          variant="outlined"
                          onClick={obj.onClick}
                          sx={{
                            border: '1px solid',
                            borderColor: 'inherit',
                            borderRadius: 0,
                            maxWidth: '80px',
                            backgroundColor: selectedSize === obj.label ? 'lightgray' : 'inherit',
                            textTransform: 'none', // Add this line
                          }}
                        >
                          {obj.icon}
                        </Button>
                      </Tooltip>
                    ))}
                  </div>

                </>
              )}

              {item.label === 'Social Media Posts' && (
                <>
                <StyledSubMenuTitleContainer>
                    <StyledSubMenu>Social Media Posts</StyledSubMenu>
                  </StyledSubMenuTitleContainer>
                  <div style={{ display: "flex", columnGap: 30, rowGap: 15, margin: "10px", flexWrap: "wrap" }}>
                    {item.children.map((obj, index) => (
                      <Tooltip key={index} title={obj.label}>
                        <PopoverListButtons onClick={obj.onClick}>
                          {obj.icon}
                        </PopoverListButtons>
                      </Tooltip>
                    ))}
                  </div>

                </>
              )}

              {item.label === 'Abstract' && (
                <>
                  <StyledSubMenuTitleContainer>
                    <StyledSubMenu>Social Media Posts</StyledSubMenu>
                  </StyledSubMenuTitleContainer>
                  <div>
                    <div style={{ display: 'flex', gap: '5px', flexDirection: "column" }}>
                      <div  onClick={() => handleTemplateSelect(addLeftToRightTemplate)}>
                        <p>Left-to-Right Template</p>
                        <canvas style={{cursor:'pointer'}} ref={leftToRightPreviewRef} />
                      </div>
                      <div onClick={() => handleTemplateSelect(addTopToBottomTemplate)}>
                        <p>Top-to-Bottom Template</p>
                        <canvas style={{cursor:'pointer'}} ref={topToBottomPreviewRef} />
                      </div>
                      <div onClick={() => handleTemplateSelect(addCircularTemplate)}>
                        <p>Circular Template</p>
                        <canvas style={{cursor:'pointer'}} ref={circularPreviewRef} />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {item.label === 'Pages' && (
                <>
                  <StyledSubMenuTitleContainer>
                    <StyledSubMenu>Canvases</StyledSubMenu>
                  </StyledSubMenuTitleContainer>
                  <div style={{ textAlign: "center" }}>
                    <Tooltip title="Add New Page">
                      <Button sx={{ my: 2 }} variant='outlined' onClick={onAddCanvas}><Add /></Button>
                    </Tooltip>
                  </div>
                  <Box display={"flex"} sx={{ overflow: "hidden", flexWrap: "wrap" }} justifyContent={"center"} gap={3}>
                    {canvasImage && canvasImage.map((can, index) => (
                      can && (
                        <img
                          className='pages-images'
                          onClick={() => switchCanvas(index)}
                          key={index}
                          src={can}
                          alt={`Page ${index + 1}`}
                        />
                      )
                    ))}
                  </Box>
                </>
              )}
            </StyledSubMenuContainer>
          </TabPanel>
        ))}
      </Box>
    </TabContext>
  );
};

export default CustomCanvasPopoverContent;
