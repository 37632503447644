import { styled } from '@mui/material/styles';
import { IconButton, Slider, ListItemButton, Box, Drawer, AppBar, BottomNavigation, Typography } from '@mui/material';
import theme from './theme';

//SiderBar styled comps-------Start
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  // paddingLeft:5,
  // backgroundColor: "whitesmoke",
  background: "#36454F",

  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const StyledSlider = styled(Slider)({
  width: "100px",
  color: "gray",
  '& .MuiSlider-thumb': {
    border: 'none',
  },
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-rail': {
    border: 'none',
  },
});


export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

export const PopoverListButtons = styled(IconButton)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  color: theme.palette.action.hover,
  borderRadius: 0,
  width: "60px",
  height: "60px",
  padding: "7px 10px",
  '&:hover': {
    backgroundColor: theme.palette.action.active
  },
  margin: 0
}))
export const StyledListButton = styled(ListItemButton)(({ theme, active }) => ({
  display: "flex",
  justifyContent: "center",
  borderRadius: 0,
  // marginBottom: theme.spacing(1),
  backgroundColor: active ? theme.palette.action.active : 'transparent',
  '&:hover': {
    color: theme.palette.action.hover,
    background: "transparent"
  },
  // marginLeft:5
}))
export const StyledSubMenuContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  display: "flex",
  gap: theme.spacing(1.25),
  flexDirection: 'column',
  background: 'linear-gradient(360deg, #0e142b -40%, #506c8a 190%)', // Gradient mix of gray and blue
  border: `1px solid #0e142b`, 
  borderRadius: theme.shape.borderRadius,
  alignItems: 'center',
}));
<div style={{ display: "flex", justifyContent:"center",alignContent:'center', margin:"10px", flexWrap: "wrap" }}>
</div>
export const StyledShapeMenuContainer = styled(Box)(({ theme }) => ({
  display: "flex", 
  justifyContent:"center",
  alignContent:'center', 
  margin:theme.spacing(1.25), 
  flexWrap: "wrap"
}));

export const StyledSubMenuTitleContainer = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(360deg, #0e142b -100%, #506c8a 140%)', // Gradient mix of gray and blue
  border: `1px solid #0e142b`, 
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1.25, 0),
  width: '100%',
}));
export const StyledSubMenuDivisionBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  width: '103.5%',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1.25, 0)
}))
export const StyledSubMenu = styled(Typography)(({ theme }) => ({
  textDecoration: 'underline',
  textDecorationColor: '#eeeeee',
  textDecorationThickness: '2px',
  textEmphasisPosition: 'underleft',
  color: '#eeeeee',
  textAlign: 'center',

}))

export const StyledDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  background: "#36454F",
  color: "#D3D3D3",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
//SiderBar styled comps-------End

export const StyledBottomNav = styled(BottomNavigation)(({ theme, open }) => ({
  justifyContent: "space-between",
  background: "#36454F",
  marginLeft: open ? 0 : `calc(${theme.spacing(7)} + 1px)`,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

