// src/components/common/SectionTitle.js

import React from "react";
import { Typography, Box } from "@mui/material";

const SectionTitle = ({ title }) => (
  <Box mb={2}>
    <Typography
      variant="h3"
      sx={{
        // backgroundColor: "#36454F",
        fontWeight: "bold",
        color: '#1e293b',
        textAlign: "center",
        marginBottom: "40px",
        padding: "10px",
        borderRadius: "5px",
        // textTransform: "uppercase",
        letterSpacing: "0.5px",
        // boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16)",
      }}
    >
      {title}
    </Typography>
  </Box>
);

export default SectionTitle;
