import { Add } from "@mui/icons-material";
import { Button, Tooltip, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';

const Homepage = () =>{
    const navigate = useNavigate();
    const toCanvas = () =>{
        navigate("/canvas");
    };
    return (
        <div style={{backgroundColor:"#36454F", height:"100%"}}>
            <div className="home">
            <h1>BioGraphix</h1>
            
            </div>
            <div className="banner">
                
            </div>
            <div style={{display:"flex", justifyContent:"center", flexDirection:"column",gap:20, padding:10}}>
                <Typography variant="h5" align="center">Start a new project</Typography>
                <div style={{display:"flex", justifyContent:"center"}}>
            <Button onClick={toCanvas} sx={{maxWidth:"150px"}} variant="outlined">
                <Add/>    
            </Button>
            </div>
            </div>
                        
        </div>
    )
}
export default Homepage;