// store.js

import { configureStore } from '@reduxjs/toolkit';
import canvasReducer from './slice/canvasSlice'; // import the canvas slice
import canvasPropsSlice from './slice/canvasPropsSlice';
const store = configureStore({
  reducer: {
    canvas: canvasReducer,
    canvasProps: canvasPropsSlice,
  },
});

export default store;
