export const handleSpraying = (spraySelectRef, setSprayObj,setSpraySelect,sprayObjRef,activeObj) => {
    if (spraySelectRef.current) {
      setSprayObj(null);
      setSpraySelect(false);
      sprayObjRef.current = null;
      spraySelectRef.current = false;
    } else if (activeObj?.[0]) {
      const selectedObj = activeObj[0];
      setSprayObj(selectedObj);
      setSpraySelect(true);
      sprayObjRef.current = selectedObj;
      spraySelectRef.current = true;
    }

    console.log("handleSpraying:", spraySelectRef.current, sprayObjRef.current);
  };