// src/components/PricingCard.js
import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  List,
  ListItem,
  Box,
} from "@mui/material";


const PricingCard = ({
  title,
  price,
  description,
  features,
  buttonText,
  buttonLink,
  highlight,
  showSave,
  showIcon,
}) => (
  
  <Card
    variant="outlined"
    sx={{
      p: 2,
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      borderRadius: 4,
      boxShadow: highlight ? 4 : 1,
      backgroundColor: highlight ? "#1e293b" : "#3b4b58",
      color: "#FFFFFF",
      transition: "transform 0.2s, box-shadow 0.2s",
      // '&:hover': {
      //   transform: 'scale(1.05)',
      //   boxShadow: highlight ? 6 : 3,
      // },
    }}
  >
    
    <CardContent
      sx={{ flexGrow: 1, display: "flex", flexDirection: "column", gap: 2 }}
    >
      {/* Header Section */}
      <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
        {" "}
        {/* Adjusted margin-bottom */}
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            backgroundColor: "#FFFFFF",
            color: "#000000",
            padding: "2px 8px",
            borderRadius: "16px",
            fontWeight: "bold",
            mr: 1,
            fontSize: "12px",
          }}
        >
          {title === "BioGraphix Teams"
            ? "For your team"
            : title === "BioGraphix Enterprise"
            ? "For your organisation"
            : "For one person"}
        </Typography>
        {showSave && (
          <Typography
            variant="body2"
            sx={{
              backgroundColor: "#E53935",
              color: "#FFFFFF",
              padding: "2px 4px",
              borderRadius: "16px",
              fontWeight: "bold",
              mr: 1,
              fontSize: "12px",
            }}
          >
            Save 33%
          </Typography>
        )}
        {showIcon && (
          <Box
            component="span"
            sx={{
              width: 28,
              height: 24,
              backgroundColor: "#FFF3E0",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              ml: 1,
            }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.546 6.545c0 .992-.568 1.851-1.395 2.272 1.304 3.493 2.67 5.21 3.871 5.344 1.35.152 2.586-.47 3.796-2.004a1.23 1.23 0 0 1 .512-.39A2.545 2.545 0 1 1 27 12.869c.002.112-.01.23-.04.353l-1.981 7.981A3.67 3.67 0 0 1 21.423 24H10.577a3.67 3.67 0 0 1-3.556-2.797l-1.98-7.981a1.329 1.329 0 0 1-.04-.353 2.545 2.545 0 1 1 1.67-1.102c.193.076.376.21.527.41 1.152 1.525 2.375 2.142 3.78 1.984 1.259-.14 2.618-1.852 3.872-5.344a2.545 2.545 0 1 1 3.696-2.272ZM7 27.25c0-.69.56-1.25 1.25-1.25h15.5a1.25 1.25 0 1 1 0 2.5H8.25c-.69 0-1.25-.56-1.25-1.25Z"
                fill="#FFB74D"
              />
            </svg>
          </Box>
        )}
      </Box>

      {/* Title and Description */}
      <Typography variant="h5" fontWeight="bold" fontSize="20px">
        {title}
      </Typography>
      <Typography variant="body1" color="text.secondary" paragraph>
        {description}
      </Typography>

      {/* Price Section */}
      <Typography variant="h4" fontWeight="bold" fontSize="32px">
        {price}
      </Typography>

      {/* Button */}
      <Button
        variant="contained"
        href={buttonLink}
        color={highlight ? "secondary" : "primary"}
        fullWidth
        sx={{ mb: 2 }}
      >
        {buttonText}
      </Button>

      {/* Features Section */}
      <Typography variant="subtitle1" gutterBottom>
        Features you'll love:
      </Typography>
      <List dense sx={{ flexGrow: 1 }}>
        {features.map((feature, index) => (
          <ListItem
            key={index}
            sx={{ display: "list-item", pl: 2, color: "#D3D3D3" }}
          >
            {feature}
          </ListItem>
        ))}
      </List>
    </CardContent>
  </Card>
);

export default PricingCard;
