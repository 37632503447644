import { fabric } from "fabric";
export const gradientCheck = (canvas, selectedColor, selectedStrokeColor) =>{
    if (canvas) {
      console.log('Canvas found, updating objects...');
  
      const activeObjects = canvas._objects;
  
      activeObjects.forEach((obj) => {
        console.log('Before update:', obj);
  
        let fillValue = selectedColor;
        let strokeValue = selectedStrokeColor;
  
        // Check for linear gradient
        if (/linear-gradient/.test(selectedColor)) {
          fillValue = createLinearGradient(selectedColor);
        }
  
        // Check for radial gradient
        if (/radial-gradient/.test(selectedColor)) {
          fillValue = createRadialGradient(selectedColor);
        }
  
        // Similarly for stroke
        if (/linear-gradient/.test(selectedStrokeColor)) {
          strokeValue = createLinearGradient(selectedStrokeColor);
        }
  
        if (/radial-gradient/.test(selectedStrokeColor)) {
          strokeValue = createRadialGradient(selectedStrokeColor);
        }
  
        // Set the properties
        obj.set({
          fill: fillValue,
          stroke: strokeValue,
          visible: true, // Ensure visibility
        });
  
        console.log('After update:', obj);
      });
  
      canvas.renderAll(); // Force re-render the canvas
    }
  }

  const createLinearGradient = (gradientStr) => {
    const parsedGradient = parseGradient(gradientStr);
  
    const angle = parsedGradient.angle;
    const coords = calculateGradientCoords(angle);
  
    return new fabric.Gradient({
      type: 'linear',
      gradientUnits: 'percentage',
      coords: coords,
      colorStops: parsedGradient.stops,
    });
  };
  
  // Function to create a radial gradient
  const createRadialGradient = (gradientStr) => {
    const parsedGradient = parseGradient(gradientStr);
  
    return new fabric.Gradient({
      type: 'radial',
      gradientUnits: 'percentage',
      coords: {
        x1: 0.5, y1: 0.5, r1: 0,  
        x2: 0.5, y2: 0.5, r2: 0.5 
      },
      colorStops: parsedGradient.stops,
    });
  };
  
  const parseGradient = (gradientStr) => {
    const stops = [];
    let angle = 0;
  
    const angleMatch = gradientStr.match(/(\d+)deg/);
    if (angleMatch) {
      angle = parseFloat(angleMatch[1]);
    }
  
    const gradientParts = gradientStr.match(/rgba?\(.+?\)\s*\d+%?/g);
  
    gradientParts.forEach((part) => {
      const [color, position] = part.split(/\s+/);
      stops.push({
        offset: parseFloat(position) / 100,
        color: color,
      });
    });
  
    return { angle, stops };
  };
  
  const calculateGradientCoords = (angle) => {
    const angleRad = (angle * Math.PI) / 180;
  
    let x1 = 0, y1 = 0, x2 = 1, y2 = 0;
  
    if (angle !== 0) {
      x1 = 0.5 + 0.5 * Math.cos(angleRad + Math.PI);
      y1 = 0.5 + 0.5 * Math.sin(angleRad + Math.PI);
      x2 = 0.5 + 0.5 * Math.cos(angleRad);
      y2 = 0.5 + 0.5 * Math.sin(angleRad);
    }
  
    return { x1, y1, x2, y2 };
  };