import React from 'react';
import {
  IconButton,
  Typography,
  Tooltip
} from '@mui/material';
import { ArrowBack, Clear } from '@mui/icons-material';
import { PopoverListButtons } from '../../../Utils/styledComps';
const CustomPathPopoverContent = ({
  selectedFeature,
  path,
  handleBackButtonClick,
  handlePopoverClose
}) => {
  const renderPopoverContent = () => {
    if (selectedFeature) {
      const feature = path.find(item => item.label === selectedFeature);
      return (
        <div style={{ height: "500px", width: '300px' }}>
          <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center", marginRight: "10px", marginLeft: "10px" }}>
            <IconButton edge="start" sx={{ borderRadius: 0 }} onClick={handleBackButtonClick}>
              <ArrowBack />
            </IconButton>
            <Typography sx={{ m: 0, fontSize: 18, color:'#f0f2f7', fontWeight: 800 }}>{selectedFeature}</Typography>
            <IconButton edge="end" sx={{ borderRadius: 0 }} onClick={handlePopoverClose}>
              <Clear />
            </IconButton>
          </div>
          <div style={{ display: "flex", columnGap: 8, margin: "10px", flexWrap: "wrap" }}>
            {feature?.children?.map((obj, index) => (
              <Tooltip key={index} title={obj.label}>
                <PopoverListButtons onClick={obj.onClick}>
                  {obj.icon}
                </PopoverListButtons>
              </Tooltip>
            ))}
          </div>
        </div>
      );
    }

    return (
      <div style={{ height: "500px", width: '300px' }}>
        <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center", marginRight: "10px" }}>
          <Typography sx={{ m: 0, pl: 2, fontSize: 18, color:'#f0f2f7', fontWeight: 800 }}>Path</Typography>
          <IconButton edge="end" sx={{ borderRadius: 0 }} onClick={handlePopoverClose}>
            <Clear />
          </IconButton>
        </div>
        <div style={{ display: "flex", columnGap: 8, margin: "10px", flexWrap: "wrap" }}>
          {path.map((obj, index) => (
            <Tooltip key={index} title={obj.label}>
              <PopoverListButtons onClick={obj.onClick}>
                {obj.icon}
              </PopoverListButtons>
            </Tooltip>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      {renderPopoverContent()}
    </>
  );
};

export default CustomPathPopoverContent;
