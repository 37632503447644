// polyfill.js (create a new file)
import { fabric } from "fabric";
(function() {
    let supportsPassive = false;
    try {
      const opts = Object.defineProperty({}, 'passive', {
        get: function() {
          supportsPassive = true;
        }
      });
      window.addEventListener('test', null, opts);
    } catch (e) {}
  
    const addEventWithOptions = (target, eventName, handler, options) => {
      if (supportsPassive) {
        target.addEventListener(eventName, handler, options);
      } else {
        target.addEventListener(eventName, handler);
      }
    };
  
    // Apply passive event listeners globally
    const addOrRemove = fabric.util.addOrRemove;
    fabric.util.addOrRemove = function addOrRemoveWithOptions(...args) {
      if (args[1] === 'wheel') {
        addEventWithOptions(args[0], args[1], args[2], { passive: true });
      } else {
        addOrRemove.apply(this, args);
      }
    };
  })();
  