import React from "react";
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from "@mui/material";

const DpiDialog = ({ open, onClose, dpi, setDpi, onDownload }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Set DPI for High-Resolution Download</DialogTitle>
      <DialogContent>
        <TextField
          label="DPI (Dots Per Inch)"  
          type="number"
          value={dpi}
          onChange={(e) => setDpi(Number(e.target.value))}
          inputProps={{ min: 60, max: 1200 }}  
          helperText="Enter a value between 60 and 1200."  
          fullWidth
          variant="outlined"  
          margin="normal"          />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDpi(300)}>Clear</Button> 
        <Button onClick={onClose}>Back</Button> 
        <Button onClick={onDownload} color="primary">Download</Button> 
      </DialogActions>
    </Dialog>
  );
};

export default DpiDialog;
