import { Add, Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, FormControl, IconButton, InputAdornment, Snackbar, TextField, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import MuiAlert from '@mui/material/Alert';

const LogIn = () => {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const toCanvas = () => {
        if (creds.password === "Biographix@123" && creds.email === "dev@gmail.com") {
            localStorage.setItem("userCreds", JSON.stringify(creds));
            navigate("/home");
        } else {
            setErrorMessage("Invalid Credentials");
            setOpenSnackbar(true);
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const [creds, setCreds] = useState({
        email: '',
        password: ''
    });

    return (
        <div style={{ backgroundColor: "#36454F", height: "100%" }}>
            <div className="home">
                <h1>BioGraphix</h1>
            </div>
            <div style={{ display: "flex", color: 'black', justifyContent: "center", marginTop:10,}}>
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: 10, maxWidth: "500px", marginBottom:10,  background: "white", borderRadius: 10, padding: 10 }}>
                    <Typography align="center" variant="h5">Log In</Typography>
                    <TextField 
                        value={creds.email} 
                        onChange={(e) => setCreds((prev) => ({
                            ...prev,
                            email: e.target.value
                        }))} 
                        label="Email" 
                        InputLabelProps={{ style: { color: 'black' } }} 
                        sx={{ input: { color: 'black' } }}
                    />

                    <TextField 
                        value={creds.password} 
                        sx={{ input: { color: 'black' } }}
                        onChange={(e) => setCreds((prev) => ({
                            ...prev,
                            password: e.target.value
                        }))} 
                        label="Password" 
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        InputLabelProps={{ style: { color: 'black' } }} 
                    />
                    <div style={{display:"flex", justifyContent:"center"}}>
                    <Button sx={{ color: 'black', maxWidth:'150px', "&:hover":{background:"black", color:"white"} }} onClick={toCanvas} variant="outlined">
                        Log In
                    </Button>
                    </div>
                </div>
            </div>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <MuiAlert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </MuiAlert>
            </Snackbar>
        </div>
    );
}

export default LogIn;
