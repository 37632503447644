import React from "react";
import { Container, Grid, Typography, Link, Box } from "@mui/material";
import PricingCard from "./subscriptionCard";
import FeatureTable from "./FeatureTable";
import SectionTitle from "./SectionTitle";
// import Timeline from "./Timeline";
// import Testimonials from "./Testimonials";
// import { pricingOptions } from "./pricingData"; // Import pricing data

const PricingPlans = () => {
  const pricingOptions = [
    {
      title: "BioGraphix Free",
      price: "₹0",
      description:
        "Design anything and bring your ideas to life. No cost, just creativity.",
      features: [
        "Easy drag-and-drop editor",
        "1M+ professionally-designed templates",
        "1000+ design types (social posts and more)",
        "3M+ stock photos and graphics",
        "AI-generated writing and designs^",
        "Design printing and delivery",
        "5GB of cloud storage",
      ],
      buttonText: "Get started",
      buttonLink: "#",
      // highlight: true, // Highlight this card
      showSave: false,
      showIcon: false,
    },
    {
      title: "BioGraphix Pro",
      price: "₹4,000",
      description:
        "Unlock premium content, more powerful design tools, and AI features.",
      features: [
        "Unlimited premium templates",
        "100M+ photos, videos, graphics, audio",
        "1000 Brand Kits to manage your brand",
        "Quickly resize and translate designs",
        "Remove backgrounds in a click",
        "Boost creativity with 20+ AI tools^",
        "Plan and schedule social content",
        "1TB of cloud storage",
        "Online customer support",
      ],
      buttonText: "Get BioGraphix Pro",
      buttonLink: "#",
      highlight: true, // Highlight this card
      showSave: true,
      showIcon: true,
    },
    {
      title: "BioGraphix Teams",
      price: "₹4,000",
      description:
        "Transform teamwork, grow your brand, and simplify the workflows.",
      features: [
        "Scale your brand and centralise assets",
        "Ensure brand consistency with approvals",
        "Edit, comment, and collaborate in real time",
        "Generate on-brand copy with AI^",
        "Team reports and insights",
        "Pay as you grow",
        "1TB of cloud storage",
        "Online customer support",
        "AI Admin Controls",
        "ISO 27001 certification and SOC 2 Type II compliance",
      ],
      buttonText: "Get BioGraphix Teams",
      buttonLink: "#",
      highlight: true, // Highlight this card
      showSave: true,
      showIcon: true,
    },
    {
      title: "BioGraphix Enterprise",
      price: "Let's talk",
      description:
        "Empower your organisation with an all-in-one workplace solution.",
      features: [
        "Centrally manage multiple teams and brands",
        "Set individual or group design approvals",
        "Manage access to the BioGraphix content and template library",
        "IP indemnity content control*",
        "SSO",
        "Automate licence provisioning with SCIM",
        "Custom apps and integrations",
        "Priority customer support",
        "Design support from Enterprise Studio creative team*",
        "Dedicated customer success manager*",
      ],
      buttonText: "Contact Sales",
      buttonLink: "#",
      highlight: true, // Highlight this card
      showSave: false,
      showIcon: true,
    },
  ];

  return (
    <Container
      sx={{
        py: 4,
        backgroundColor: "#f0f0f0",
        width: "100%",
        maxWidth: { xs: "100%", md: "100%" },
        px: { xs: 2, sm: 3 },
      }}
    >
      {/* Section title for pricing plans */}
      <SectionTitle title="Design anything with the right plan" />

      <Grid container spacing={2}>
        {pricingOptions.map((option, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <PricingCard {...option} />
          </Grid>
        ))}
      </Grid>
      <Typography variant="body2" color="#1e293b" align="center" sx={{ mt: 4 }}>
        <Link href="#" color="primary" underline="hover">
          K-12 educational organisations
        </Link>{" "}
        and{" "}
        <Link href="#" color="primary" underline="hover">
          not-for-profits
        </Link>{" "}
        can enjoy premium features for free.
      </Typography>

      {/* Section title for feature comparison table */}
      <Box mt={6}>
        <SectionTitle title="Compare Features" />
      </Box>

      {/* Feature comparison table */}
      <FeatureTable />

      {/* <Box mt={4} >
        <Timeline/>
        <Testimonials />
      </Box> */}
    </Container>
  );
};

export default PricingPlans;
