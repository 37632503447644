import React from "react";
import { TableHead, TableRow, TableCell, Button, IconButton } from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import FeatureIcon from "@mui/icons-material/Star"; // Replace with any relevant icon

const FeatureTableHeader = ({ onToggle, isOpen }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ fontWeight: "bold", fontSize: "26px", display: "flex", alignItems: "center" }}>
          <FeatureIcon sx={{ mr: 1 }} /> 
          General Features
          <IconButton
            onClick={onToggle}
            sx={{
              ml: 1,
              transition: "transform 0.8s ease", 
              transform: isOpen ? "rotate(180deg)" : "rotate(0deg)", 
            }}
          >
            {isOpen ? (
              <ExpandLess fontSize="large" /> 
            ) : (
              <ExpandMore fontSize="large" /> 
            )}
          </IconButton>
        </TableCell>
        <TableCell align="center">
          <Button variant="contained" color="primary">Free</Button>
        </TableCell>
        <TableCell align="center">
          <Button variant="contained" color="secondary">Pro</Button>
        </TableCell>
        <TableCell align="center">
          <Button variant="contained" color="secondary">Teams</Button>
        </TableCell>
        <TableCell align="center">
          <Button variant="contained" color="secondary">Enterprise</Button>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default FeatureTableHeader;
