import React from 'react';
import Popover from '@mui/material/Popover';
import { styled } from '@mui/material/styles';
// Define your styled Popover component
const StyledPopover = styled(Popover)(({ theme, open, currentPopover }) => ({
  '& .MuiPaper-root': {
    marginLeft: theme.spacing(currentPopover === 'shapes' || currentPopover === 'file' || currentPopover === 'canvas' || currentPopover === 'home' || currentPopover === 'path' || currentPopover === 'view' || currentPopover === 'combinations' ? 1 : currentPopover === 'color' || currentPopover === 'text' || currentPopover === 'alignment' ? -5 : 0),
    marginTop: open ? (currentPopover === 'shapes' || currentPopover === 'file' || currentPopover === 'canvas' || currentPopover === 'home' || currentPopover === 'path' || currentPopover === 'view' || currentPopover === 'combinations' ? -40 : currentPopover === 'color' || currentPopover === 'text' || currentPopover === 'alignment' || currentPopover === 'clipboard' ? 0 : -4.5) : 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

// Define your CustomPopover component
const CustomPopover = ({ isOpen, anchorEl, onClose, currentPopover, children }) => (
  <StyledPopover
    open={isOpen}
    anchorEl={anchorEl}
    onClose={onClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    currentPopover={currentPopover}
  >
    {children}
  </StyledPopover>
);

export default CustomPopover;
