import { createSlice, configureStore } from '@reduxjs/toolkit';

// Initial state
const initialState = {
    backgroundColor: 'rgba(255,255,255,255)',
    size: { width: 595, height: 842 },
    zoom: 1.0,
    opacity:1,
    fillColor: 'transparent'
};

// Create slice
const canvasPropsSlice = createSlice({
    name: 'canvasProps',
    initialState,
    reducers: {
        setBackgroundColor(state, action) {
            state.backgroundColor = action.payload;
        },
        setSize(state, action) {
            state.size = action.payload;
        },
        setZoom(state, action) {
            state.zoom = action.payload;
        },
        setOpacity(state, action) {
            state.opacity = action.payload;
        },
        setFillColor(state, action) {
            state.fillColor = action.payload;
        }
    },
});

// Export actions and reducer
export const { setBackgroundColor, setSize, setZoom, setGuides } = canvasPropsSlice.actions;
export default canvasPropsSlice.reducer;

