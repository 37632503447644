// canvasSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeCanvas: null, // initially no canvas
};

const canvasSlice = createSlice({
  name: 'canvas',
  initialState,
  reducers: {
    setActiveCanvas: (state, action) => {
        console.log("setActiveCanvas: ",state, action.payload)
      state.activeCanvas = action.payload; 
    },
  },
});

export const { setActiveCanvas } = canvasSlice.actions;

export default canvasSlice.reducer;
