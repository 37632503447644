// src/components/FeatureTable/FeatureTableRow.js

import React from "react";
import { TableRow, TableCell, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

const FeatureTableRow = ({ feature }) => {
  const renderCell = (value) => {
    if (typeof value === "boolean") {
      return value ? <CheckIcon color="success" /> : <ClearIcon color="error" />;
    }
    return <Typography>{value}</Typography>;
  };

  return (
    <TableRow>
      <TableCell>{feature.feature}</TableCell>
      <TableCell align="center">{renderCell(feature.free)}</TableCell>
      <TableCell align="center">{renderCell(feature.pro)}</TableCell>
      <TableCell align="center">{renderCell(feature.teams)}</TableCell>
      <TableCell align="center">{renderCell(feature.enterprise)}</TableCell>
    </TableRow>
  );
};

export default FeatureTableRow;
