import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e', 
    },
    background: {
      default: '#000000',
      paper: '#36454F', 
    },
    text: {
      primary: '#D3D3D3', 
      secondary: '#F5F5F5', 
    },
    action: {
      active: '#4c6a7b', 
      hover:"#f0f2f7"
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
    h1: {
      color: '#F5F5F5',
      fontSize: '2.5rem',
    },
    body1: {
      color: '#D3D3D3', 
      fontSize: '1rem',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color:'#f0f2f7',
          borderColor:"black",
          '&:hover': {
            borderColor: 'whitesmoke', 
          },
        },
        
      },
    },
  },

});

export default theme;
